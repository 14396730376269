import { createContext, useCallback, useContext } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Table from "./Table";
import Form from "./Form";

const MenuContext = createContext<any>(null);

const Menu = () => {
  const navigate = useNavigate();

  const redirectCreate = useCallback(
    (event?: { preventDefault: Function }) => {
      if (event?.preventDefault) {
        event.preventDefault();
      }
      navigate("/config/menu/create");
    },
    [navigate]
  );

  const redirectEdit = useCallback(
    (id: number, event?: { preventDefault: Function }) => {
      if (event?.preventDefault) {
        event.preventDefault();
      }
      navigate(`/config/menu/edit/${id}`);
    },
    [navigate]
  );

  return (
    <MenuContext.Provider value={{ redirectCreate, redirectEdit }}>
      <Routes>
        <Route path="/" element={<Table />} />
        <Route path="/create" element={<Form type="create" />} />
        <Route path="/edit/:id" element={<Form type="edit" />} />
        <Route path="*" element={<Navigate to="/config/menu" />} />
      </Routes>
    </MenuContext.Provider>
  );
};

export function useMenuContext() {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error(
      "useMenuContext() must be used within a MenuContext provider."
    );
  }
  return context;
}

export default Menu;
