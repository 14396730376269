import {
  useState,
  useRef,
  useCallback,
  FormEvent,
  useEffect,
  FC,
  useMemo,
  Fragment,
} from "react";
import {
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import {
  Overlay,
  Popover,
  Nav,
  Row,
  Button,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";

import { Role } from "../../../interfaces";
import RoleListItem from "./RoleListItem";

interface RoleListProps {
  roles: Role.IRole[];
  selected: Role.IRole | null;
  provided: DroppableProvided;
  snapshot: DroppableStateSnapshot;
  onRoleClick: (role: Role.IRole) => void;
  onRoleDelete: (role: Role.IRole) => void;
  onRoleCreate: (event: FormEvent<HTMLFormElement>) => void;

  children?: React.ReactNode;
}

const RoleList: FC<RoleListProps> = ({
  roles,
  selected,
  provided,
  onRoleClick,
  onRoleCreate,
  onRoleDelete,
}) => {
  const [t] = useTranslation();

  const createTarget = useRef(null);
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);
  const [isFormEmpty, setIsFormEmpty] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const descRef = useRef<HTMLTextAreaElement>(null);

  const handleOnSubmitBtn = (e: any) => {
    e.preventDefault();
    if (!Boolean(nameRef?.current?.value) || !Boolean(descRef?.current?.value))
      return setIsFormEmpty(true);
    setShow(false);
    e.target.form.requestSubmit();
  };

  const handleClick = useCallback(
    (event: any) => {
      setShow(!show);
      setTarget(event.target);
    },
    [setShow, setTarget, show]
  );

  useEffect(() => {
    let timer: any;
    if (isFormEmpty) {
      timer = setTimeout(() => setIsFormEmpty(false), 2000);
    }
    return () => clearTimeout(timer);
  }, [isFormEmpty]);

  const items = useMemo(() => {
    return roles.map((role: Role.IRole, index: number) => (
      <Draggable
        key={"draggable.role.".concat(role.id.toString() || "0")}
        children={(provided, snapshot) => (
          <RoleListItem
            key={"role.".concat(role.id.toString() || "0")}
            role={role}
            provided={provided}
            snapshot={snapshot}
            onRoleClick={onRoleClick}
            onRoleDelete={onRoleDelete}
            active={selected ? selected.id === role.id : index === 0}
          />
        )}
        draggableId={"id.draggable.role.".concat(role.id.toString() || "0")}
        index={index}
      />
    ));
  }, [roles, selected, onRoleClick, onRoleDelete]);

  if (roles.length === 0) {
    return (
      <Row className="d-flex justify-content-center">
        <Spinner animation="border" role="status" className="mx-auto" />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row
        xxl={6}
        xl={6}
        lg={6}
        md={12}
        sm={12}
        xs={12}
        children={
          <Button
            ref={createTarget}
            className="w-100"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
            children={t("label.action.create", { ns: "application.misc" })}
          />
        }
      />
      <Overlay
        show={show}
        container={createTarget}
        target={target}
        placement="bottom"
        containerPadding={20}
      >
        <Popover>
          <Popover.Header
            as="h3"
            children={t("label.action.create", { ns: "application.misc" })}
            style={{ backgroundColor: "#0d6efd" }}
          />
          <Popover.Body as={Form} onSubmit={onRoleCreate} id="form">
            <Form.Group controlId="role.name">
              <Form.Label>
                {t("roles.role_name", { context: "label" })}
                <span style={{ color: "red" }}>&nbsp;*</span>
              </Form.Label>
              <Form.Control
                placeholder={t("roles.role_name", { context: "placeholder" })}
                name="role.name"
                type="text"
                ref={nameRef}
              />
            </Form.Group>
            <Form.Group controlId="role.description">
              <Form.Label>
                {t("roles.role_description", { context: "label" })}
                <span style={{ color: "red" }}>&nbsp;*</span>
              </Form.Label>
              <Form.Control
                placeholder={t("roles.role_description", {
                  context: "placeholder",
                })}
                name="role.description"
                as="textarea"
                ref={descRef}
                rows={5}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100 my-2"
              onClick={handleOnSubmitBtn}
            >
              {t("label.action.create", { ns: "application.misc" })}
            </Button>
            {isFormEmpty && (
              <Alert variant="danger">
                {t("roles.role_info", { context: "error" })}
              </Alert>
            )}
          </Popover.Body>
        </Popover>
      </Overlay>
      <hr />
      <Row>
        <Nav
          as="div"
          variant="pills"
          ref={provided.innerRef}
          className="d-flex flex-column"
          children={[...items, provided.placeholder]}
        />
      </Row>
    </Fragment>
  );
};

export default withTranslation()(RoleList);
