import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { capitalizeFirstLetter } from "../../../../utils/utils";
import { capitalize } from "lodash";

const RelationsSearchBar = (props: any) => {
    const { t } = useTranslation();

    const [value, setValue] = useState()


    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.handleSearchValue(value, props.tableName)
    }

    const handleChange = (e: any) => {
        setValue(e.target.value)
    }

    return (
        <Form onSubmit={handleSubmit} className='w-100'>
            <Form.Group controlId="relationshipSearchBar">
                <InputGroup>
                    <Form.Control
                        placeholder={capitalize(props.tableName).concat(" - ", t('relationship_table.enter_search_value'))}
                        onChange={handleChange}
                        value={value}
                        type="text" />
                    <Button className="input-group-text" type="submit" variant="secondary">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16" fill="currentColor"
                            className="bi bi-search" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                            />
                        </svg>
                    </Button>
                </InputGroup>
            </Form.Group>
        </Form>
    )
}

export default RelationsSearchBar