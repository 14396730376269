import { t } from "i18next";
import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { State } from "src/interfaces";
import Select from "react-select";

interface EditStateModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: (editedItem: State.IState) => void;
  item: State.IState | null;
  defaultExists: boolean | undefined;
  defaultState: State.IState | undefined;
}

const EditStateModal: React.FC<EditStateModalProps> = ({
  show,
  onHide,
  onConfirm,
  item,
  defaultExists,
  defaultState,
}) => {
  const [editedItem, setEditedItem] = useState<State.IState | null>(item);
  const [selectedActions, setSelectedActions] = useState<any | null>(null);
  const [isChecked, setIsChecked] = useState<any | boolean>(false);

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const handleSave = () => {
    if (editedItem) {
      onConfirm({
        ...editedItem,
        actions: selectedActions.map((action: any) => action.value),
        is_default: isChecked,
      });
    }
  };

  const options = useMemo(
    () => [
      {
        value: "create",
        label: t("states.actions_labels.create", {
          ns: "application.misc",
        }),
      },
      {
        value: "read",
        label: t("states.actions_labels.read", {
          ns: "application.misc",
        }),
      },
      {
        value: "update",
        label: t("states.actions_labels.update", {
          ns: "application.misc",
        }),
      },
      {
        value: "delete",
        label: t("states.actions_labels.delete", {
          ns: "application.misc",
        }),
      },
    ],
    []
  );

  useEffect(() => {
    setEditedItem(item);
    setIsChecked(editedItem?.is_default);
    const actions = options.filter((option) =>
      editedItem?.actions.includes(option.value)
    );
    setSelectedActions(actions);
  }, [editedItem?.actions, editedItem?.is_default, item, options]);

  let canEditDefault = true;

  if (!defaultExists || defaultState?.id === editedItem?.id) {
    canEditDefault = false;
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`${t("states.edit_modal.title", {
            ns: "application.misc",
          })} ${editedItem?.id}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editedItem && (
          <Form>
            <Row>
              <Form.Group as={Col} controlId="formId">
                <Form.Label>
                  {t("states.edit_modal.state", { ns: "application.misc" })}
                </Form.Label>
                <Form.Select value={editedItem.state} disabled>
                  <option value={editedItem.state}>{editedItem.state}</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="formEntity">
                <Form.Label>
                  {t("states.edit_modal.actions", { ns: "application.misc" })}
                </Form.Label>
                <Select
                  options={options}
                  value={selectedActions}
                  isMulti
                  onChange={(selectedActions: any) =>
                    setSelectedActions(selectedActions)
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mt-3">
              <Form.Group as={Col}>
                <Form.Check
                  inline
                  label={t("states.edit_modal.default", {
                    ns: "application.misc",
                  })}
                  name="is_default"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  disabled={canEditDefault}
                />
              </Form.Group>
            </Row>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("states.edit_modal.btn_cancel", { ns: "application.misc" })}
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!selectedActions || !selectedActions.length}
        >
          {t("states.edit_modal.btn_edit", { ns: "application.misc" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditStateModal;
