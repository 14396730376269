/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ExportExcel } from "src/components/shared/ExportExcel/ExportExcel";
import useEntityService from "../../../services/entity.service";
import useExcelReaderService from "../../../services/excelreader.service";
import { capitalizeFirstLetter } from "../../../utils/utils";
import ModalForm from "../Modal/ModalForm/ModalForm";
import { capitalize } from "lodash";
interface ICorrectFomat {
  state: Boolean
  message: String
  itemsLeft: String[]
};

export default function FileImportModal({
  entity,
  showModal,
  handleClose,
  fields
}: {
  entity: any;
  showModal: boolean;
  handleClose: Function;
  fields: any
}) {
  const { t } = useTranslation();
  const { readFile, loading, data, setData } = useExcelReaderService();
  const { bulkCreateRecords } = useEntityService();
  const [response, setResponse] = useState<any>();
  const [errors, setErrors] = useState([]);
  const [collapseArray, setCollapseArray] = useState<any>({});
  const [correctFormat, setCorrectFormat] = useState<ICorrectFomat>({ state: true, message: "", itemsLeft: [] })
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate()

  const handleImportFile = (event: any) => {
    event.preventDefault();
    setResponse("");
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      readFile(file);
    }
  };

  const sendData = () => {
    bulkCreateRecords({
      entity,
      data,
      fn: (response: any) => {
        setResponse(response);

        if (response.error) {
          setErrors(response.errors);
          response.errors.forEach((item: any) =>
            setCollapseArray({ ...collapseArray, [item.field]: true })
          );
        }
        setData([]);
        navigate(0)
      },
    });

    if (inputRef && inputRef.current) inputRef.current.value = "";
  };

  const toggleCollapseArray = (field: any) => {
    setCollapseArray({ ...collapseArray, [field]: !collapseArray[field] });
  };

  const formatErrorText = (error: any) => {
    let text = error.split(" ");
    let firstWord = t(capitalizeFirstLetter(text[0]));

    text.shift();

    return firstWord + " " + text.join(" ");
  };

  const reset = () => {
    setData([]);
    setResponse(null);
    setErrors([]);
    setCollapseArray({});
    setCorrectFormat({ state: true, message: "", itemsLeft: [] })
    handleClose();
  };

  const renderTotalCreatedMessage = () => {
    let total = response?.data[0].totalRecordsCreated;
    //return `${total} ${t("record")}${total === 1 ? "" : "s"} ${t("created")}`;
    return t("file_import.records_created", { count: total })
  };

  useEffect(() => {
    if (data.length < 1) return;
    const dataFields = Object.keys(data[0]).filter((item: string) => item !== "row_id" && item !== "id")
    const tableFields = fields.data.map((field: { name: string }) => field.name)

    const dataFieldsOrdered = dataFields.sort()
    const tableFielsOrdered = tableFields.sort()

    const isEqual = JSON.stringify(dataFieldsOrdered) === JSON.stringify(tableFielsOrdered)

    const allFields = new Set([...dataFields, ...tableFields])
    const differentFields = Array.from(allFields).filter((item: string) => !dataFields.find((item2: string) => item === item2))

    setCorrectFormat({
      state: isEqual,
      message: isEqual ? "pass" : "not pass",
      // if arrays aren"t equal, check what entity field are left in the excel to match
      itemsLeft: isEqual ? [] : differentFields
    })

  }, [data, entity])

  return (
    <ModalForm
      header={t("file_import.import_from", { source: "Excel" })}
      handleClose={reset}
      show={showModal}
      onHide={reset}
    >
      <h5>{t("file_import.select_file")}</h5>
      <Container>
        <label htmlFor="entity-file-upload" style={{ borderRadius: "5px", background: "white", outline: "1px solid black", padding: "10px 50px" }}>
          {t("file_import.select_file")}
        </label>
        <input
          type="file"
          ref={inputRef}
          id="entity-file-upload"
          style={{ display: "none" }}
          onChange={handleImportFile}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </Container>

      {
        correctFormat.state
        && data?.length > 0
        && !loading
        && (
          <div className="container mt-4 alert alert-success">
            <h6 className="text-center">
              {
                capitalize(
                  t(
                    "file_import.load",
                    {
                      descriptor: inputRef?.current?.value.split(/(\\|\/)/g).pop(),
                      context: "success",
                    }
                  )
                )
              }
            </h6>
          </div>
        )
      }

      {
        !correctFormat.state && (
          <div className="container mt-2 alert alert-danger">
            <h6 className="text-center">
              {
                capitalize(
                  t(
                    "file_import.load",
                    {
                      descriptor: inputRef?.current?.value.split(/(\\|\/)/g).pop(),
                      context: "error",
                    }
                  )
                )
              }
            </h6>
            <p>
              {
                capitalize(
                  t(
                    "file_import.data_match",
                    {
                      descriptor: inputRef?.current?.value.split(/(\\|\/)/g).pop(),
                      context: "incorrectly",
                    }
                  )
                )
              }
            </p>
            <p>
              {
                capitalize(
                  t(
                    "file_import.review_following",
                    {
                      descriptor: inputRef?.current?.value.split(/(\\|\/)/g).pop(),
                      items: correctFormat.itemsLeft.join(", "),
                    }
                  )
                )
              }
            </p>
          </div>
        )
      }

      {response && (
        <div className="container mt-4">
          <h6
            className={`text-center alert ${response.error ? "alert-warning" : "alert-success"
              }`}
          >
            {renderTotalCreatedMessage()}
          </h6>
        </div>
      )}

      {errors && errors.length > 0 && (
        <div className="container my-4">
          {errors.map((item: any, index) => (
            <div className="container mb-2" key={index}>
              <div
                key={index}
                className="alert alert-danger"
                onClick={() => toggleCollapseArray(item.index)}
                role="button"
              >
                {
                  capitalize(
                    t(
                      "file_import.error_found",
                      {
                        error: t("file_import.record_with_index", { index: item.index + 1 }),
                      }
                    )
                  )
                }
              </div>
              <Collapse in={collapseArray[item.index]}>
                <ul className="list-group">
                  {Object.entries(item).map((key: any, value) => {
                    if (key[0] !== "index") {
                      return (
                        <li className="list-group-item text-danger" key={value}>
                          {formatErrorText(key[1])}
                        </li>
                      );
                    }
                  })}
                </ul>
              </Collapse>
              {index !== errors.length - 1 && <hr />}
            </div>
          ))}
        </div>
      )}
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col>
          <button
            className="btn btn-success mt-4"
            disabled={data.length === 0 || !correctFormat.state}
            onClick={sendData}
          >
            {t("file_import.import")}
          </button>
        </Col>
        {
          fields && (
            <Col xs={3} style={{ paddingTop: "1.5rem" }}>
              <ExportExcel
                data={[]}
                isTree={false}
                template={true}
                sheetName={entity}
                columns={fields.data}
                buttonText={t("file_import.download_template")}
                fileName={entity.concat("_template_", new Date().toISOString())}
              />
            </Col>
          )
        }
      </Row>
    </ModalForm>
  );
}
