export class UserRepository {
  username: string = "";
  email: string = "";
  password: string = "";
  active: boolean = true;
  type?: string;
  id?: number;
  domains: number[] = [];

}

class User extends UserRepository {
  constructor(
    username: string,
    email: string,
    password: string,
    active: boolean = true,
    domains: number[]
  ) {
    super();
    this.username = username;
    this.email = email;
    this.password = password;
    this.active = active;
    this.domains = domains;
  }
}

class UserBuilder extends UserRepository {
  user: UserRepository;
  constructor() {
    super();
    this.user = new UserRepository();
  }
  setEmail(email: string) {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) throw new Error("Invalid email");
    this.user.email = email;
    return this;
  }
  build() {
    return this.user;
  }
}

export class UserForm extends User {
  usernameIsValid = false;
  emailIsValid = false;
  passwordIsValid = false;
  activeIsValid = false;
  overallValidity = false;
  constructor(
    username: string,
    email: string,
    password: string,
    active: boolean,
    domains: number[]
  ) {
    super(username, email, password, active, domains);
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.emailIsValid = emailRegex.test(this.email);
    this.activeIsValid = typeof this.active === "boolean";
    this.passwordIsValid = this.password.trim().length >= 5;
    this.usernameIsValid = this.username.trim().length >= 3;
    this.overallValidity = this.validateAll();
  }
  validateAll() {
    return (
      this.usernameIsValid &&
      this.emailIsValid &&
      this.passwordIsValid &&
      this.activeIsValid
    );
  }
}

class NullUser {
  username: string;
  email: string;
  password: string;
  active: boolean;
  constructor() {
    this.username = "";
    this.email = "";
    this.password = "";
    this.active = true;
  }
}
