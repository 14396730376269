import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import { RelationshipKey, Entity, Field } from "../../../interfaces";
import useEntityService from "../../../services/entity.service";
import { useEntityTable } from "../EntityTable/EntityTable";
import { TFunction } from "i18next";

interface RelationshipSearchContainerProps {}

interface RelationOption {
  label: string;
  value: string;
  entity: string;
}

interface EntityOption {
  label: string;
  value: number;
  entity: Entity.IEntity;
}

const RelationshipSearchContainer: FC<
  RelationshipSearchContainerProps & { t: TFunction }
> = ({ t }) => {
  const { getRelationshipsKeys, getFields, getAll } = useEntityService();

  const { rows, entity, currentView, dispatchCurrentView } = useEntityTable();

  const [fields, setFields] = useState<Array<Field>>([]);
  const [relation, setRelation] = useState<RelationOption | null>(null);
  const [targetEntity, setTargetEntity] = useState<EntityOption | null>(null);

  const [entityOptions, setEntityOptions] = useState<Array<EntityOption>>([]);
  const [relationOptions, setRelationOptions] = useState<Array<RelationOption>>(
    []
  );

  const indexableFields = useMemo(
    () => fields.filter((field) => field.indexable),
    [fields]
  );

  const uniqueFields = useMemo(
    () => fields.filter((field) => field.unique),
    [fields]
  );

  const selected = useMemo(
    () => currentView.relationships,
    [currentView.relationships]
  );

  const submitItem = useMemo(() => {
    if (!relation || !targetEntity) return null;

    const { value: _relation } = relation;
    const { entity: _entity } = targetEntity;

    const uniqueValues = uniqueFields
      .map((field) => _entity[`data_${field.name}`])
      .filter((value) => value);

    return {
      relation: _relation,
      value: _entity.id || uniqueValues.join(",") || 0,
    };
  }, [relation, targetEntity, uniqueFields]);

  const updateFilterData = useCallback(
    (data: any) =>
      dispatchCurrentView({
        relationships: data,
      }),
    [dispatchCurrentView]
  );

  const formSubmitHandler = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (submitItem) {
        updateFilterData([submitItem]);
      }
    },
    [submitItem, updateFilterData]
  );

  useEffect(() => {
    if (relation) {
      getFields({
        entity: relation.entity,
        fn: (response: { data: Array<Field> }) => {
          setFields(response.data);
        },
      });
    }
  }, [getFields, relation]);

  useEffect(() => {
    if (relation) {
      getAll({ entity: relation.entity, fn: () => void 0 }).then(({ data }) => {
        const options = new Array<EntityOption>();

        if (data instanceof Array) {
          data.forEach((cEntity, index) => {
            let label: Array<string> = [];

            indexableFields.forEach((field) => {
              const value: Entity.keyPrefix<
                "data_",
                string
              > = `data_${field.name}`;
              label.push(cEntity[value]);
            });

            options.push({
              label: label.join(", "),
              value: index,
              entity: cEntity,
            });
          });
        }

        setEntityOptions(options);
      });
    }
  }, [getAll, indexableFields, relation]);

  useEffect(() => {
    getRelationshipsKeys({
      entity,
      fn: ({ data }: { data: Array<RelationshipKey.IKey> }) => {
        const options = new Array<RelationOption>();

        if (data instanceof Array) {
          const [relations] = data;
          Object.keys(relations).forEach((index) => {
            const r = relations[index];

            const [relation] = [r.l_entity, r.r_entity].filter(
              (e) => e !== entity
            );

            if (relation) {
              options.push({
                value: index,
                label: t("relationships." + index, {
                  ns: "entities." + entity,
                }),
                entity: relation,
              });
            }
          });
        }
        setRelationOptions(options);
      },
    });
  }, [getRelationshipsKeys, entity, t]);

  return (
    <Form onSubmit={formSubmitHandler} className="w-100">
      <Select
        onChange={(selected, meta) => setRelation(selected || null)}
        placeholder={t("entity_table.type_to_search")}
        isDisabled={rows && !rows.length}
        options={relationOptions}
        className="mb-2"
      />

      <Select
        onChange={(selected, meta) => {
          setTargetEntity(selected || null);
        }}
        placeholder={t("entity_table.type_to_search")}
        isDisabled={rows && !rows.length}
        options={entityOptions}
        value={targetEntity}
        className="mb-2"
      />

      <Button
        children={t("entity_table.apply_filter")}
        disabled={!relation || !targetEntity || (rows && !rows.length)}
        className="w-100"
        type="submit"
      />
    </Form>
  );
};

export default withTranslation()(RelationshipSearchContainer);
