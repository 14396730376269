import { TFunction, withTranslation } from "react-i18next";
import { useMenuCategoryContext } from "./Menu_category";
import { useCallback, useEffect, useState } from "react";
import { SortType } from "rsuite-table";
import { IPagination, IServerResponse } from "src/interfaces";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import RTable from "rsuite/Table";
import { capitalize } from "lodash";
import { Pagination } from "rsuite";
import useMenuCategoryService from "src/services/menu_category.service";
import { useNavigate } from "react-router-dom";

interface TableProps {
  t: TFunction;
}

interface ICategoryMenu {
  id: number;
  name: string;
  visible: boolean;
  active: boolean;
}

const Table = ({ t }: TableProps): JSX.Element => {
  const { redirectCreate, redirectEdit } = useMenuCategoryContext();
  const { getManyPaginated, edit: editMenuById } = useMenuCategoryService();
  const navigate = useNavigate();
  const [categoryMenu, setCategoryMenu] = useState<Array<any>>([]);
  const [sortColumn, setSortColumn] = useState<any>(null);
  const [sortType, setSortType] = useState<SortType>("asc");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: page,
    limit: limit,
    hasPreviousPage: false,
    hasNextPage: false,
    previousPage: null,
    nextPage: null,
    startIndex: 0,
    numPages: 0,
    recordsTotal: 0,
  });

  const handleSortColumn = (sortColumn: any, sortType: any): void => {
    setSortColumn(sortColumn);
    setSortType(sortType || "asc");
  };

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  const onRowClickEdit = useCallback(
    (row) => redirectEdit(row.id),
    [redirectEdit]
  );

  const onRowActivateClick = useCallback(
    (row: ICategoryMenu) => {
      const { id } = row;
      const newRow: ICategoryMenu = { ...row, active: !row.active };
      editMenuById({ id, data: { data: newRow } })
        .then((response) => {
          navigate(`/config/menu_category`);
        })
        .catch((error) => console.error(error));
    },
    [editMenuById, navigate]
  );

  useEffect(() => {
    getManyPaginated({
      limit,
      page,
      fn: (
        response: IServerResponse<{
          data: Array<ICategoryMenu>;
          pagination: IPagination;
        }>
      ) => {
        setPagination(response.pagination);
        setCategoryMenu(response.data);
        setLoading(false);
      },
    });
  }, [getManyPaginated, limit, sortColumn, sortType, page]);
  return (
    <Container fluid>
      <Row>
        <Col className="text-truncate" xxl={10} xs={10} md={6}>
          <h1>{t("config.menu_category")}</h1>
        </Col>
        <Col className="text-truncate" xxl={2} xs={2} md={6}>
          <Button
            variant="primary"
            onClick={redirectCreate}
            className="text-nowrap g-0 m-0 p-2"
            children={t("label.action.create", { ns: "application.misc" })}
          />
        </Col>
      </Row>
      <RTable
        title={t("config.menu_category") || ""}
        onRowClick={onRowClickEdit}
        loading={loading}
        data={categoryMenu}
        showHeader
        autoHeight
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <RTable.Column flexGrow={1}>
          <RTable.HeaderCell>
            {capitalize(t("category_menu.name") || "").trim()}
          </RTable.HeaderCell>
          <RTable.Cell dataKey="name" />
        </RTable.Column>
        <RTable.Column flexGrow={1}>
          <RTable.HeaderCell>
            {capitalize(t("category_menu.visible") || "").trim()}
          </RTable.HeaderCell>
          <RTable.Cell>
            {(rowData) => (
              <Form.Check // prettier-ignore
                type="checkbox"
                checked={rowData.visible}
              />
            )}
          </RTable.Cell>
        </RTable.Column>
        <RTable.Column flexGrow={1}>
          <RTable.HeaderCell>
            {capitalize(
              t("label.action.active_inactive", { ns: "application.misc" })
            )
              .concat("?")
              .trim()}
          </RTable.HeaderCell>
          <RTable.Cell className="d-flex justify-content-center align-items-center">
            {(rowData) => {
              if (rowData.active) {
                return (
                  <button
                    className="btn btn-danger"
                    onClick={(event) =>
                      onRowActivateClick(rowData as ICategoryMenu)
                    }
                  >
                    {capitalize(
                      t("label.action.inactive", { ns: "application.misc" })
                    ).trim()}
                  </button>
                );
              } else {
                return (
                  <button
                    className="btn btn-success"
                    onClick={(event) =>
                      onRowActivateClick(rowData as ICategoryMenu)
                    }
                  >
                    {capitalize(
                      t("label.action.active", { ns: "application.misc" })
                    ).trim()}
                  </button>
                );
              }
            }}
          </RTable.Cell>
        </RTable.Column>
      </RTable>
      <Pagination
        last
        first
        ellipsis
        limit={limit}
        className="p-3"
        maxButtons={10}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
        limitOptions={[10, 30, 50]}
        next={pagination.hasNextPage}
        prev={pagination.hasPreviousPage}
        total={pagination.recordsTotal}
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        locale={{
          total:
            t("label.table.total_records", {
              ns: "application.misc",
              total: "{0}",
            }) || "",
          limit:
            t("label.table.limit_to", {
              ns: "application.misc",
              limit: "{0}",
            }) || "",
          skip:
            t("label.table.skip", { ns: "application.misc", page: "{0}" }) ||
            "",
          first: t("label.table.first", { ns: "application.misc" }) || "",
          prev: t("label.table.prev", { ns: "application.misc" }) || "",
          next: t("label.table.next", { ns: "application.misc" }) || "",
          last: t("label.table.last", { ns: "application.misc" }) || "",
          more: t("label.table.more", { ns: "application.misc" }) || "",
        }}
      />
    </Container>
  );
};

export default withTranslation()(Table);
