import { Button, Col, Container, Form, Image, Row} from "react-bootstrap";
import IconButton from "rsuite/IconButton";
import Table from "rsuite/Table";
import { Check2, PencilSquare, Plus, Trash, X } from 'react-bootstrap-icons';
import { useEffect, useState, FormEvent } from "react";
import useUserDataService from "src/services/user.service";
import { useTranslation } from "react-i18next";
import UserProfile from "src/interfaces/IUserProfile";
import { Cell } from "rsuite-table";
import PhonesModal from "./PhoneModal";
import PasswordModal from "./ChangePassword";
import AddressesModal from "./AddressModal";
import DeleteModal from "./DeleteModal";
import usePersonService from "src/services/person.service";
import IUser from "src/interfaces/IUser";

const profileDefaultObject: UserProfile.IUserProfile  = {
  user_id: "",
  username: "",
  first_name: "",
  last_name: "",
  title: "",
  salutation: "",
  description: "",
  department: 0,
  do_not_call: false,
  photo: "",
  person_id: 0,
  roles: [],
  phones: [],
  addresses: [],
  modified_user_id: "",
};

const Profile = () => {
  const [userProfileData, setuserProfileData] = useState<UserProfile.IUserProfile>(profileDefaultObject);
  const [phones, setPhones] = useState<UserProfile.IPhone[]>();
  const [isPhoneModalOpen, setPhoneModalOpen] = useState<boolean>(false);
  const [phoneObject, setPhoneObject] = useState<UserProfile.IPhone>();
  const [isAddressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const [addressObject, setAddressObject] = useState<UserProfile.IAddress>();
  const [addresses, setAddresses] = useState<UserProfile.IAddress[]>();
  const [isPasswordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteActionType, setDeleteActionType] = useState<string>("");
  const [validated, setValidated] = useState(false);
  const {userProfile, updatePassword} = useUserDataService();
  const { t } = useTranslation();
  const { updatePerson } = usePersonService();

  useEffect(() => {
    userProfile({fn: (result: any) => {
      setuserProfileData({
        ...result.data[0],
        modified_user_id: result.data[0].user_id
      });
      setPhones(result.data[0].phones);
      setAddresses(result.data[0].addresses);
    }})
    .catch((e: Error) => {
      console.log(e);
    })
  }, []);

  /* Password methods */
  const handlePasswordCloseModal = () => {
    setPasswordModalOpen(false);
  };

  const handleSavePassword = (passwordToSave: IUser.IPassword) => {
    updatePassword({
      fn: (response: any) => {},
      data: passwordToSave,
    });

    setPasswordModalOpen(false);
  };

  const handlePasswordOpenModal = () => {
    setPasswordModalOpen(true);
  };

  /* Phones methods */
  const handlePhonesCloseModal = () => {
    setPhoneModalOpen(false);
  };

  const handleSavePhone = (phoneToSave: UserProfile.IPhone) => {
    const currentPhones = userProfileData?.phones || [];
    const temporalId = phoneToSave.temp_id === 0 ? 1 : phoneToSave.temp_id;
    let phoneIndex = -1;
    if (temporalId) {
      phoneIndex = currentPhones?.findIndex((phone) => phone.temp_id === phoneToSave.temp_id);
      if (phoneIndex === -1) {
        currentPhones?.push(phoneToSave);        
      }
    } else if (phoneToSave.id) {
      phoneIndex = currentPhones?.findIndex((phone) => phone.id === phoneToSave.id);      
    }

    if (phoneIndex > -1) {
      currentPhones[phoneIndex] = phoneToSave;
    }

    setPhones(currentPhones);
    setPhoneModalOpen(false);
  };

  const handlePhonesOpenModal = () => {
    setPhoneObject({
      phone_number: "",
      type_description: "",
      phone_type: 0,
      active: false,
      temp_id: phones?.length
    } as UserProfile.IPhone);
    setPhoneModalOpen(true); 
  }

  /* Delete methods */
  const handleDeleteModalCancel = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteModalConfirm = () => {    
    if (deleteActionType === 'phone') {      
      const currentPhones = phones || [];
      const index = currentPhones.findIndex((phone) => phone === phoneObject);
      if (index > -1) { 
        currentPhones.splice(index,1);
        setPhones(currentPhones);        
      }      
    } else if (deleteActionType === 'address') {
      const currentAddress = addresses || [];
      const index = currentAddress.findIndex((address) => address === addressObject);
      if (index > -1) { 
        currentAddress.splice(index,1);
        setAddresses(currentAddress);        
      }   
    }
    setDeleteModalOpen(false);
  };

  /* Addresses methods */
  const handleAddressCloseModal = () => {
    setAddressModalOpen(false);
  }

  const handleSaveAddress = (addressToSave: UserProfile.IAddress) => {
    const currentAddresses = addresses || []; 
    const temporalId = addressToSave.temp_id === 0 ? 1 : addressToSave.temp_id;
    let addressIndex = -1;

    if (temporalId) {
      addressIndex = currentAddresses?.findIndex((address) => address.temp_id === addressToSave.temp_id);
      if (addressIndex === -1) {
        currentAddresses.push(addressToSave); 
      }      
    } else if (addressToSave.id) {      
        addressIndex = currentAddresses?.findIndex((address) => address.id === addressToSave.id);
    }

    if (addressIndex > -1) {
      currentAddresses[addressIndex] = addressToSave;
    }
    
    setAddresses(currentAddresses);
    setAddressModalOpen(false);
  };

  const handleAddressOpenModal = () => {
    setAddressObject({
      street: "",
      city: "",
      state: "",
      postalcode: "",
      country: "",
      temp_id: addresses?.length
    } as UserProfile.IAddress);
    setAddressModalOpen(true);
  }

  /* Save User profile */
  const saveUserProfileData = () => {
    const newUserProfile = { 
      data : {
        ...userProfileData,
        phones: phones ?? userProfileData.phones,
        addresses: addresses ?? userProfileData.addresses,
      },      
    };

    updatePerson({
      fn: (response: any) => {},
      id: newUserProfile.data.person_id,
      data: newUserProfile,
    });
  }

  /* Button options */
  const ActionsButtons = ({ rowData, dataKey, ...props }: any) => {
    const handleEditOnClick = () => {
      switch (props.actiontype) {
        case 'phone': 
          setPhoneObject(rowData);
          setPhoneModalOpen(true);
          break;
        case 'address':
          setAddressObject(rowData); 
          setAddressModalOpen(true);
          break;
        default: break;                    
      }
    };
    const handleDeleteOnClick = () => {     
      setDeleteActionType(props.actiontype);
      switch (props.actiontype) {
        case 'phone': 
          setPhoneObject(rowData);
          break;
        case 'address':
          setAddressObject(rowData); 
          break;
        default: break;                    
      }
      setDeleteModalOpen(true);
    };

    return (
      <Table.Cell {...props} style={{padding: '8px'}}>
        <Button onClick={handleEditOnClick} title={t("label.action.edit", { ns: "application.misc" })} size="sm" style={{margin: '0 8px 0 0'}}><PencilSquare /></Button>
        <Button onClick={handleDeleteOnClick} title={t("label.action.delete", { ns: "application.misc" })} size="sm"><Trash /></Button>
      </Table.Cell>
    );
  };

  const checkValidForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      saveUserProfileData();
    }

    setValidated(true);
  };

  /* Active custom cell */
  const ActiveCustomCell = ({ rowData, dataKey, ...props }: any) => {
    return (
      <Cell {...props} style={{padding: '8px'}}>
        <IconButton appearance="subtle" icon={ rowData.active ? <Check2 style={{color: "green"}}/> : <X style={{color: "red"}}/>} />
      </Cell>
    );
  };
  
  return (
    <Container fluid>
        <Row>
          <Col sm={4}>
            <Form>
              <Row>
                <Col align="center">
                  <Image src={userProfileData?.photo} width="200px" height="200px" rounded />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="username">
                    <Form.Label>{t("user_profile.username")}</Form.Label>
                    <Form.Control type="text" value={userProfileData?.username} disabled readOnly autoComplete="false"/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="userRoles">
                    <Form.Label>{t("user_profile.roles")}</Form.Label>
                    <Form.Control as="textarea" value={userProfileData?.roles?.map((role) => role.item)} disabled readOnly/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col align="center">
                  <Button variant="primary" onClick={() => handlePasswordOpenModal()}>
                    {t("user_profile.change_password")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col sm={8}>          
            <Form noValidate validated={validated} onSubmit={checkValidForm}>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="personFirstName">
                    <Form.Label>{t("user_profile.first_name")}</Form.Label>
                    <Form.Control type="text" value={userProfileData?.first_name} onChange={(e) => {
                      setuserProfileData({
                        ...userProfileData,
                        first_name: e.target.value
                      }); 
                    }} required/>
                    <Form.Control.Feedback type="invalid">
                      {t("user_profile.first_name_feedback")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="personLastName">
                    <Form.Label>{t("user_profile.last_name")}</Form.Label>
                    <Form.Control type="text" value={userProfileData?.last_name} onChange={(e) => {
                      setuserProfileData({
                        ...userProfileData,
                        last_name: e.target.value
                      }); 
                    }} required/>
                    <Form.Control.Feedback type="invalid">
                      {t("user_profile.last_name_feedback")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>            
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="personTitle">
                    <Form.Label>{t("user_profile.title")}</Form.Label>
                    <Form.Control type="text" placeholder={t("user_profile.title_placeholder")} value={userProfileData?.title} onChange={(e) => {
                      setuserProfileData({
                        ...userProfileData,
                        title: e.target.value
                      }); 
                    }}/>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="personSaludation">
                    <Form.Label>{t("user_profile.saludation")}</Form.Label>
                    <Form.Control type="text" placeholder={t("user_profile.saludation_placeholder")} value={userProfileData?.salutation} onChange={(e) => {
                      setuserProfileData({
                        ...userProfileData,
                        salutation: e.target.value
                      }); 
                    }}/>
                  </Form.Group>
                </Col>            
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="personDescription">
                    <Form.Label>{t("user_profile.description")}</Form.Label>
                    <Form.Control as="textarea" placeholder={t("user_profile.description_placeholder")} value={userProfileData?.description} onChange={(e) => {
                      setuserProfileData({
                        ...userProfileData,
                        description: e.target.value
                      }); 
                    }}/>
                  </Form.Group>
                </Col>  
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="personDepartment">
                    <Form.Label>{t("user_profile.department")}</Form.Label>
                    <Form.Control type="text" placeholder={t("user_profile.department_placeholder")} value={userProfileData?.department} onChange={(e) => {
                      setuserProfileData({
                        ...userProfileData,
                        department: Number(e.target.value)
                      }); 
                    }}/>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="personDoNotCall">
                    <Form.Label>{t("user_profile.do_not_call")}</Form.Label>
                    <Form.Check type="switch" checked={userProfileData?.do_not_call} onChange={(e) => {
                      setuserProfileData({
                        ...userProfileData,
                        do_not_call: !userProfileData.do_not_call
                      }); 
                    }}/>
                  </Form.Group>
                </Col>            
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col sm={11}>
                        <span>{t("user_profile.phones.title")}</span>
                      </Col>
                      <Col sm={1} align="rigth">
                        <Button onClick={() => handlePhonesOpenModal()}>
                            <Plus />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        {phones?.length ? (
                          <Table data={phones} autoHeight>
                            <Table.Column width={300}>
                              <Table.HeaderCell>{t("user_profile.phones.phone_number")}</Table.HeaderCell>
                              <Table.Cell dataKey="phone_number"/>
                            </Table.Column>
                            <Table.Column width={300}>
                              <Table.HeaderCell>{t("user_profile.phones.phone_type")}</Table.HeaderCell>
                              <Table.Cell dataKey="type_description" />
                            </Table.Column>
                            <Table.Column width={150}>
                              <Table.HeaderCell>{t("user_profile.phones.active")}</Table.HeaderCell>
                              <ActiveCustomCell dataKey="active" />
                            </Table.Column>
                            <Table.Column width={200}>
                              <Table.HeaderCell>{t("user_profile.actions")}</Table.HeaderCell>
                              <ActionsButtons dataKey="id" actiontype="phone"/>
                            </Table.Column>
                          </Table>
                        ) : t("user_profile.table_without_data")}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>                          
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col sm={11}>
                        <span>{t("user_profile.addresses.title")}</span>
                      </Col>
                      <Col sm={1} align="rigth">
                        <Button onClick={() => handleAddressOpenModal()}>
                            <Plus />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        {addresses?.length ? (
                          <Table data={addresses} autoHeight>
                          <Table.Column width={200}>
                            <Table.HeaderCell>{t("user_profile.addresses.street")}</Table.HeaderCell>
                            <Table.Cell dataKey="street" />
                          </Table.Column>
                          <Table.Column width={150}>
                            <Table.HeaderCell>{t("user_profile.addresses.city")}</Table.HeaderCell>
                            <Table.Cell dataKey="city" />
                          </Table.Column>
                          <Table.Column width={150}>
                            <Table.HeaderCell>{t("user_profile.addresses.state")}</Table.HeaderCell>
                            <Table.Cell dataKey="state" />
                          </Table.Column>
                          <Table.Column width={100}>
                            <Table.HeaderCell>{t("user_profile.addresses.postal_code")}</Table.HeaderCell>
                            <Table.Cell dataKey="postalcode" />
                          </Table.Column>
                          <Table.Column width={150}>
                            <Table.HeaderCell>{t("user_profile.addresses.country")}</Table.HeaderCell>
                            <Table.Cell dataKey="country" />
                          </Table.Column>
                          <Table.Column width={200}>
                            <Table.HeaderCell>{t("user_profile.actions")}</Table.HeaderCell>
                            <ActionsButtons dataKey="id" actiontype="address" />
                          </Table.Column>
                        </Table>
                        ) : t("user_profile.table_without_data")}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>                          
              </Row>
              <Row>
                <Col align="center">
                  <Button variant="primary" type="submit">
                    {t("label.action.save", { ns: "application.misc" })}
                  </Button>
                </Col>            
              </Row>           
            </Form>
          </Col>
        </Row>
      <PasswordModal
        showPasswordModal={isPasswordModalOpen}
        handleClose={handlePasswordCloseModal}
        handleConfirm={handleSavePassword}
        key={'Pw'+Date.now()}
      />
      {phoneObject && (<PhonesModal
        showPhonesModal={isPhoneModalOpen}
        handleClose={handlePhonesCloseModal}
        phonedata= {phoneObject}
        handleConfirm={handleSavePhone}
        key={'P'+Date.now()}
      />)}
      {addressObject && (<AddressesModal
        showAddressModal={isAddressModalOpen}
        handleClose={handleAddressCloseModal}
        addressdata={addressObject}
        handleConfirm={handleSaveAddress}
        key={'A'+Date.now()}
      />)}
      <DeleteModal
        showDeleteModal={isDeleteModalOpen}
        handleCancel={handleDeleteModalCancel}
        actiontype={deleteActionType}
        handleConfirm={handleDeleteModalConfirm}
      />
    </Container>
    );
};

export default Profile;