import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LoginAuth from "../../../hook/useLoginAuth";
import useLocalStorage from "../../../hook/useLocalStorage";
import "./signInSignUp.css";

export default function SignIn(props: any) {
  const { storedValue: credentials } = useLocalStorage(
    "da.authorization.credentials"
  );
  const [t] = useTranslation("application.misc");
  const { signup, SignupProcess } = LoginAuth.useSignup();

  const daEmailField = React.useRef<HTMLInputElement>(null);
  const daPasswordField = React.useRef<HTMLInputElement>(null);
  const [validated, setValidated] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInvalidEmail(false);

    const form = event.currentTarget;
    const emailField = form.elements.namedItem(
      "da_field_email"
    ) as HTMLInputElement;
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!form.checkValidity() || !regexEmail.test(emailField.value)) {
      if (!regexEmail.test(emailField.value)) {
        setInvalidEmail((previous) => true);
      }
      event.stopPropagation();
    } else {
      const formData = new FormData(event.target as HTMLFormElement);
      const formParams = new URLSearchParams(formData as any);

      SignupProcess(formParams);
    }
    setValidated(true);
  };

  if (signup && !signup.error) {
    return <Navigate to="/login" />;
  }

  if (credentials && credentials.logged && !credentials.error) {
    if (credentials.created_at + credentials.expires_in > Date.now()) {
      return <Navigate to="/" />;
    }
  }

  return (
    <Container className="d-flex flex-column justify-content-start align-items-start g-0 m-0 px-5 py-5">
      <h1>{t("login.welcome")}</h1>
      <p>{t("login.please_register_your_account")}</p>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="w-100"
      >
        <Row className="mt-3">
          <Col>
            <Form.Group controlId="da_field_email">
              <Form.Label>{t("login.please_enter_your_email")}</Form.Label>
              <Form.Control
                type="email"
                ref={daEmailField}
                name="da_field_email"
                placeholder={t("login.please_enter_your_email")}
                isInvalid={invalidEmail}
                className="email-login"
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("login.email_feedback")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group controlId="da_field_password">
              <Form.Label>{t("login.please_enter_your_password")}</Form.Label>
              <Form.Control
                type="password"
                ref={daPasswordField}
                name="da_field_password"
                placeholder={t("login.please_enter_your_password")}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("login.password_feedback")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button variant="primary" type="submit">
              {t("login.register")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
