import environment from "../environment";
import HttpHandler from "../hook/http-handler";
import { useCallback } from "react";

import { useAppSelector } from "../app/hooks";
import { selectDomain } from "../reducers/domain/domainSlice";
import { getToken } from "../reducers/auth";

import { IServerResponse, State } from "../interfaces";

interface IParams {
  id?: number | string;
  data?: State.IState | any;
  relationship?: string;
  limit?: number;
  page?: number;
  filter?: string;
  related?: string;
  errorHandler?: Function;
}

const API_States_Endpoint = environment.apiUrl.concat("/", "states");

export default function useStateService() {
  const currentDomain = useAppSelector(selectDomain);
  const credentials = useAppSelector(getToken);
  const { sendRequest } = HttpHandler();

  const getAll = useCallback(
    function <IType = IServerResponse<State.IState>>(): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              resolve(response);
            };
            sendRequest(
              {
                url: API_States_Endpoint,
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const checkIfTableExists = useCallback(
    function <IType = IServerResponse<State.IState>>(): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              resolve(response);
            };
            sendRequest(
              {
                url: `${API_States_Endpoint}/exists`,
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const createRecord = useCallback(
    function <IType = IServerResponse<State.IState>>({
      data,
      errorHandler = (error: any) => console.log(error),
    }: IParams): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              resolve(response);
            };
            sendRequest(
              {
                url: API_States_Endpoint,
                method: "POST",
                body: data,
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const updateRecord = useCallback(
    function <IType = IServerResponse<State.IState>>({
      id,
      data,
      errorHandler = (error: any) => console.log(error),
    }: IParams): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              resolve(response);
            };
            sendRequest(
              {
                url: API_States_Endpoint.concat("/", id?.toString() || "0"),
                method: "PUT",
                body: data,
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const deleteRecord = useCallback(
    function <IType = IServerResponse<State.IState>>({
      id = 0,
    }: any): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        const finish = function (response: IType | PromiseLike<IType>) {
          resolve(response);
        };

        if (credentials && credentials.logged) {
          sendRequest(
            {
              url: `${API_States_Endpoint}/${id}`,
              method: "DELETE",
              headers: {
                Authorization: `${credentials.token_type} ${credentials.access_token}`,
                "Content-Type": "application/json",
                domain: currentDomain.id,
              },
              body: [],
            },
            finish
          );
        }
      });
    },
    [credentials, currentDomain.id, sendRequest]
  );

  return {
    deleteRecord,
    getAll,
    checkIfTableExists,
    updateRecord,
    createRecord,
  };
}
