import { FC, createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TFunction, Trans, withTranslation } from "react-i18next";
import Table, { CellProps } from "rsuite/Table";
import styled from "styled-components";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import usePersonService from "src/services/person.service";
import UserManager from "./User/UserManager";
import { useUsersContext } from "./Users";
import { Person } from "src/interfaces";

const EasyButton = styled.span(() => ({
  borderRadius: 20,
  backgroundColor: "transparent",
  textDecoration: "none",
  padding: "10px 25px",
  cursor: "pointer",
  display: "inline",
  border: "none",
  color: "blue",
  margin: 5,

  "&:hover": {
    backgroundColor: "skyblue",
    textDecoration: "underline",
    color: "white",
  },
}));

const FormPersonContext = createContext<{
  reloadUsers: () => void;
  users: Array<any>;
}>({
  reloadUsers: () => void 0,
  users: [],
});

interface IFormPersonProps {
  t: TFunction;
  type: "create" | "edit";
}

interface IAddressEditable extends Person.IAddress {
  $status?: "EDIT" | "VIEW";
}

interface IPhoneEditable extends Person.IPhone {
  $status?: "EDIT" | "VIEW";
};

const ListItem = styled.li(() => ({
  listStyleType: "number",
  "&:hover": {
    backgroundColor: "inherit !important",
    color: "inherit !important",
    cursor: "pointer",
  },
}));

const EditableCell = ({ rowIndex, rowData, dataKey, onEdit, ...props }: { onEdit: Function; } & CellProps<IAddressEditable | IPhoneEditable>) => {
  const editing = rowData?.$status === "EDIT";
  return editing
    ? (
      <Table.Cell {...props}
        className="table-content-editing">
        <Form.Control
          name="person.editable-cell"
          className="rs-input"
          type="text"
          required

          defaultValue={dataKey && rowData?.[dataKey as keyof (IAddressEditable | IPhoneEditable)]}
          onBlur={event => {
            if (isNaN(Number(rowIndex))) return;
            onEdit(
              Number(rowIndex),
              dataKey?.toString() || "",
              event.target.value
            );
          }}
        />
      </Table.Cell>
    )
    : (
      <Table.Cell
        dataKey={dataKey?.toString() || ""}
        rowData={rowData}
        {...props} />
    );
};

const OptionCell = ({ rowIndex, rowData, dataKey, onEdit, options, ...props }: CellProps<IAddressEditable | IPhoneEditable> & { options: Array<{ value: number; label: string; }>; onEdit: Function; }) => {
  const editing = rowData?.$status === "EDIT";
  return editing
    ? (
      <Table.Cell {...props}
        className="table-content-editing">
        <Form.Select
          name="person.editable-cell"
          className="rs-input"
          required
          defaultValue={dataKey && rowData?.[dataKey as keyof (IAddressEditable | IPhoneEditable)]}
          onBlur={event => {
            if (isNaN(Number(rowIndex))) return;
            onEdit(
              Number(rowIndex),
              dataKey?.toString() || "",
              event.target.value
            );
          }}

          children={
            options
              .map((option) => (
                <option data-option={option} value={option.value} children={option.label} />
              ))
          }
        />
      </Table.Cell>
    )
    : (
      <Table.Cell
        children={
          dataKey
          && options
            // eslint-disable-next-line eqeqeq
            .find((option) => option.value == rowData?.[dataKey as keyof (IAddressEditable | IPhoneEditable)])
            ?.label
        }
        rowData={rowData}
        {...props} />
    );
}

const ActionCell = (
  { rowIndex, rowData, dataKey, onClickEdit, onClickDelete, ...props }:
    CellProps<IAddressEditable | IPhoneEditable> &
    {
      onClickEdit: (id: number) => void;
      onClickDelete: (id: number) => void;
    }
) => {
  return (
    <Table.Cell {...props}>
      <EasyButton
        onClick={() => {
          if (isNaN(Number(rowIndex))) return;
          onClickEdit(Number(rowIndex));
        }}
        children={
          rowData?.$status === "VIEW"
            ? <Trans ns="application.misc" i18nKey="label.action.edit" />
            : <Trans ns="application.misc" i18nKey="label.action.save" />
        }
      />
      <EasyButton
        onClick={() => {
          if (isNaN(Number(rowIndex))) return;
          onClickDelete(Number(rowIndex));
        }}
        children={
          <Trans ns="application.misc" i18nKey="label.action.delete" />
        }
      />
    </Table.Cell>
  );
};

const FormPerson: FC<IFormPersonProps> = ({ t, type }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { phoneTypes } = useUsersContext();
  const {
    edit: editPerson,
    getOne: getPerson,
    create: createPerson,
    getUsersById: getPersonUsers,
  } = usePersonService();

  const [person, set_person] = useReducer(
    (state: Person.IPerson, newState: any): Person.IPerson => ({ ...state, ...newState }),
    {
      first_name: "",
      last_name: "",

      assistant: "",
      assistant_phone: "",

      description: "",
      salutation: "",
      title: "",

      do_not_call: false,
      department: 0,

      addresses: [],
      phones: [],
      users: [],
    }
  );

  const [users, set_users] = useReducer(
    (state: any[], newState: any[]): any[] => [...newState],
    []
  );

  const [addresses, setAddresses] = useState<IAddressEditable[]>([]);
  const [phones, setPhones] = useState<IPhoneEditable[]>([]);

  const onChangeEditionAddress = useCallback((rowIndex: number, key: string, value: string) => {
    if (rowIndex === -1) return;

    setAddresses((addresses) => addresses
      .map((address, index) => {
        if (index === rowIndex) {
          return { ...address, [key]: value };
        }

        return address;
      })
    );
  }, []);

  const onClickEditAddress = useCallback((rowIndex: number) => {
    if (rowIndex === -1) return;

    setAddresses((addresses) => addresses
      .map((address, index) => {
        if (index === rowIndex) {
          switch (address.$status) {
            case "VIEW":
              return { ...address, $status: "EDIT" };
            case "EDIT":
              return { ...address, $status: "VIEW" };
            default:
              return address;
          }
        }

        return address;
      })
    );
  }, []);

  const onClickDeleteAddress = useCallback((rowIndex: number) => {
    if (rowIndex === -1) return;

    setAddresses((addresses) => addresses
      .filter((address, index) => index !== rowIndex)
    );
  }, []);

  const onChangeEditionPhone = useCallback((rowIndex: number, key: string, value: string) => {
    if (rowIndex === -1) return;

    setPhones((phones) => phones
      .map((phone, index) => {
        if (index === rowIndex) {
          return { ...phone, [key]: value };
        }

        return phone;
      })
    );
  }, []);

  const onClickEditPhone = useCallback((rowIndex: number) => {
    console.log("rowIndex", rowIndex);
    if (rowIndex === -1) return;

    setPhones((phones) => phones
      .map((phone, index) => {
        if (index === rowIndex) {
          switch (phone.$status) {
            case "VIEW":
              return { ...phone, $status: "EDIT" };
            case "EDIT":
              return { ...phone, $status: "VIEW" };
            default:
              return phone;
          }
        }

        return phone;
      })
    );
  }, []);

  const onClickDeletePhone = useCallback((rowIndex: number) => {
    if (rowIndex === -1) return;

    setPhones((phones) => phones
      .filter((phone, index) => index !== rowIndex)
    );
  }, []);

  const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data: Person.IPerson = {
      ...person,
      phones: phones
        .map((phone) => {
          delete phone.$status;
          return phone;
        }),
      addresses: addresses
        .map((address) => {
          delete address.$status;
          return address;
        }),
    };

    switch (type) {
      case "create":
        createPerson({
          // TODO: Include phone and phone types at creating new person.
          data,
        })
          .then((response) => {
            navigate(`/config/users`);
          })
          .catch((error) => console.error(error));
        break;
      case "edit":
        editPerson({ id, data: { data } })
          .then((response) => {
            navigate(`/config/users`);
          })
          .catch((error) => console.error(error));
        break;
      default:
        console.error("Invalid form type suitable for submit.");
        break;
    }
  }, [addresses, createPerson, editPerson, id, navigate, person, phones, type]);

  // ? Reload users when user is created.
  const reloadUsers = useCallback(() => {
    getPersonUsers({ id })
      .then((response) => set_users(response.data))
      .catch((error) => console.error(error));
  }, [getPersonUsers, id]);

  // ? Convert IAddress to IAddressEditable to edit on table.
  useEffect(() => {
    setAddresses(person.addresses.map((address) => ({ ...address, $status: "VIEW" })));
    setPhones(person.phones.map((phone) => ({ ...phone, $status: "VIEW" })));
  }, [person.addresses, person.phones]);

  // ? Get from Person API and Users API if type is edit.
  useEffect(() => {
    if (type === "edit" && id) {
      getPerson({ id })
        .then((response) => {
          const person = response.data;
          set_person(person);
        })
        .catch((error) => console.error(error));
      getPersonUsers({ id })
        .then((response) => set_users(response.data))
        .catch((error) => console.error(error));
    }
  }, [getPerson, getPersonUsers, id, type]);

  return (
    <FormPersonContext.Provider value={{ reloadUsers, users }}>
      <Form onSubmit={onSubmit}>
        <Row>
          <Accordion as="ol" flush defaultActiveKey="basic_information">
            <Accordion.Item eventKey="basic_information">
              <Accordion.Header>
                <ListItem className="mx-3">
                  {t("single_user.basic_information")}
                </ListItem>
              </Accordion.Header>
              <Accordion.Body data-section="basic_information" className="p-2">
                <Row>
                  <Col>
                    <FloatingLabel label={t("single_user.salutation")} className="my-2" controlId="person.salutation">
                      <Form.Control
                        required
                        onChange={(e) => set_person({ salutation: e.target.value })}
                        value={person.salutation}
                        name="person.salutation"
                        maxLength={10}
                        type="text"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel label={t("single_user.title")} className="my-2" controlId="person.title">
                      <Form.Control
                        required
                        onChange={(e) => set_person({ title: e.target.value })}
                        value={person.title}
                        name="person.title"
                        maxLength={50}
                        type="text"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FloatingLabel label={t("single_user.first_name")} className="my-2" controlId="person.first_name">
                      <Form.Control
                        required
                        onChange={(e) => set_person({ first_name: e.target.value })}
                        value={person.first_name}
                        name="person.first_name"
                        maxLength={100}
                        type="text"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel label={t("single_user.last_name")} className="my-2" controlId="person.last_name">
                      <Form.Control
                        required
                        onChange={(e) => set_person({ last_name: e.target.value })}
                        value={person.last_name}
                        name="person.last_name"
                        maxLength={100}
                        type="text"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FloatingLabel label={t("single_user.description")} className="my-2" controlId="person.description">
                      <Form.Control
                        onChange={(e) => set_person({ description: e.target.value })}
                        value={person.description}
                        name="person.description"
                        maxLength={255}
                        as="textarea"
                        required
                        rows={3}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="address">
              <Accordion.Header>
                <ListItem className="mx-3">
                  {t("single_user.address")}
                </ListItem>
              </Accordion.Header>
              <Accordion.Body data-section="address" className="p-2">
                <Row className="mb-1 d-flex justify-content-end">
                  <Col className="d-flex justify-content-end">
                    <Button
                      disabled={false}
                      variant="primary"
                      children={t("label.action.add", { ns: "application.misc" })}
                      onClick={() => setAddresses((addresses) => [...addresses, { id: 0, $status: "EDIT" } as IAddressEditable])}
                    />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Table data={addresses}
                    style={{ width: '100%' }}>
                    <Table.Column align="center" fixed width={150}>
                      <Table.HeaderCell children={t("single_user.id")} />
                      <Table.Cell dataKey="id" />
                    </Table.Column>

                    <Table.Column align="center" fixed width={150}>
                      <Table.HeaderCell children={t("single_user.country")} />
                      <EditableCell
                        dataKey="country"
                        onEdit={onChangeEditionAddress}

                      />
                    </Table.Column>

                    <Table.Column align="center" fixed width={150}>
                      <Table.HeaderCell children={t("single_user.state")} />
                      <EditableCell
                        dataKey="state"
                        onEdit={onChangeEditionAddress}
                      />
                    </Table.Column>

                    <Table.Column align="center" fixed width={150}>
                      <Table.HeaderCell children={t("single_user.city")} />
                      <EditableCell
                        dataKey="city"
                        onEdit={onChangeEditionAddress}
                      />
                    </Table.Column>

                    <Table.Column align="center" fixed width={150}>
                      <Table.HeaderCell children={t("single_user.street")} />
                      <EditableCell
                        dataKey="street"
                        onEdit={onChangeEditionAddress}
                      />
                    </Table.Column>

                    <Table.Column align="center" fixed width={150}>
                      <Table.HeaderCell children={t("single_user.postal_code")} />
                      <EditableCell
                        dataKey="postalcode"
                        onEdit={onChangeEditionAddress}
                      />
                    </Table.Column>

                    <Table.Column align="center" fixed width={250} colSpan={4}>
                      <Table.HeaderCell children={t("label.help.select_action", { ns: "application.misc", count: 1 })} />
                      <ActionCell
                        dataKey="id"
                        onClickEdit={onClickEditAddress}
                        onClickDelete={onClickDeleteAddress}
                      />
                    </Table.Column>
                  </Table>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="additional_information">
              <Accordion.Header>
                <ListItem className="mx-3">
                  {t("single_user.additional_information")}
                </ListItem>
              </Accordion.Header>
              <Accordion.Body data-section="additional_information" className="p-2">
                <Row>
                  <Col xxl="7" xl="7" lg="7" md="7" sm="7" xs="7">
                    <FloatingLabel label={t("single_user.department")} className="my-2" controlId="person.department">
                      <Form.Control
                        onChange={(e) => set_person({ department: Number(e.target.value) })}
                        value={person.department}
                        name="person.department"
                        type="number"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <Form.Group className="my-2" controlId="person.do_not_call">
                      <Form.Label>{t("single_user.do_not_call")}</Form.Label>
                      <Form.Switch
                        onChange={(e) => set_person({ do_not_call: e.target.checked })}
                        checked={person.do_not_call}
                        name="person.do_not_call"
                        type="checkbox"
                      >
                      </Form.Switch>
                    </Form.Group>
                  </Col>
                  <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                    <Row className="d-flex justify-content-end align-items-center h-100">
                      <Button
                        disabled={false}
                        variant="primary"
                        children={t("label.action.add", { ns: "application.misc" })}
                        onClick={() => setPhones((phones) => [...phones, { id: 0, $status: "EDIT" } as IPhoneEditable])}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Table data={phones}
                    style={{ width: '100%' }}>
                    <Table.Column align="center" fixed width={150}>
                      <Table.HeaderCell children={t("single_user.id")} />
                      <Table.Cell dataKey="id" />
                    </Table.Column>


                    {
                      phoneTypes && (
                        <Table.Column align="center" fixed width={150}>
                          <Table.HeaderCell children={t("single_user.phone_type")} />
                          <OptionCell
                            dataKey="type_id"
                            onEdit={onChangeEditionPhone}
                            options={
                              phoneTypes
                                .data
                                .map((phoneType: { id: number; type_description: string; active: boolean; }) => ({
                                  value: phoneType.id,
                                  label: phoneType.type_description
                                }))
                            }
                          />
                        </Table.Column>
                      )
                    }

                    <Table.Column align="center" fixed width={150}>
                      <Table.HeaderCell children={t("single_user.phone_number")} />
                      <EditableCell
                        dataKey="phone_number"
                        onEdit={onChangeEditionPhone}
                      />
                    </Table.Column>

                    <Table.Column align="center" fixed width={250} colSpan={4}>
                      <Table.HeaderCell children={t("label.help.select_action", { ns: "application.misc", count: 1 })} />
                      <ActionCell
                        dataKey="id"
                        onClickEdit={onClickEditPhone}
                        onClickDelete={onClickDeletePhone}
                      />
                    </Table.Column>
                  </Table>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            {
              type === "edit" && person.id && (
                <Accordion.Item eventKey="account_information">
                  <Accordion.Header>
                    <ListItem className="mx-3">
                      {t("single_user.account", { count: users.length || 0 })}
                    </ListItem>
                  </Accordion.Header>
                  <Accordion.Body data-section="account_information" className="p-2">
                    <UserManager
                      users={users}
                      contact_id={person.id}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              )
            }
          </Accordion>
        </Row>
        <Row>
          <Col className="d-flex flex-row-reverse">
            <Button
              variant="primary"
              disabled={false}
              type="submit"
            >
              {t("label.action.save", { ns: "application.misc" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormPersonContext.Provider>
  );
}

export function useFormPerson() {
  const context = useContext(FormPersonContext);
  if (!context) {
    throw new Error("useFormPerson must be used within a FormPersonProvider");
  }
  return context;
}

export default withTranslation()(FormPerson);