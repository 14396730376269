import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./RightClickMenu.module.css";

const RightClickMenu = (props: any) => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  const handlerContextMenu = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
        setAnchorPoint({ x: event.pageX, y: event.pageY });
      }
      if (props.attrName !== "0" && props.attrName !== "") setShow(true);
    },
    [props.attrName]
  );

  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

  useEffect(() => {
    handlerContextMenu(null);
  }, [props.attrName]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    // document.addEventListener("contextmenu", handlerContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handlerContextMenu);
    };
  });

  return (
    <Fragment>
      {props.children}
      {show ? (
        <ul
          className={classes.menu}
          style={{
            top: anchorPoint.y,
            left: anchorPoint.x,
          }}
        >
          <li
            onClick={() => {
              props.onClickData(props.attrName);
            }}
          >
            {t("group_by")} {props.attrLabel}
          </li>
          {props.inTree && (
            <Fragment>
              <hr className="divider" />
              <li
                onClick={(e) => {
                  props.onSetOperation(
                    "TOTAL",
                    props.attrName,
                    props.typeOfData
                  );
                }}
              >
                {t("total_rows")}
              </li>
              <li
                onClick={(e) => {
                  props.onSetOperation("MAX", props.attrName, props.typeOfData);
                }}
              >
                {t("Max")}
              </li>
              <li
                onClick={(e) => {
                  props.onSetOperation("MIN", props.attrName, props.typeOfData);
                }}
              >
                {t("Min")}
              </li>
              {props.typeOfData === "number" && (
                <Fragment>
                  <li
                    onClick={(e) => {
                      props.onSetOperation(
                        "SUM",
                        props.attrName,
                        props.typeOfData
                      );
                    }}
                  >
                    {t("Sum")}
                  </li>
                  <li
                    onClick={(e) => {
                      props.onSetOperation(
                        "PROM",
                        props.attrName,
                        props.typeOfData
                      );
                    }}
                  >
                    {t("PROM")}
                  </li>
                </Fragment>
              )}
            </Fragment>
          )}
        </ul>
      ) : (
        <> </>
      )}
    </Fragment>
  );
};

export default RightClickMenu;
