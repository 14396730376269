import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { Routes, Route, Link, useLocation, Navigate, useNavigate } from "react-router-dom";

import usePersonService from 'src/services/person.service';
import { IServerResponse } from 'src/interfaces';
import Form from './Form';
import Table from './Table';

const UsersContext = createContext<any>(null);

export default function Users() {
  const { getPhoneTypes, _delete } = usePersonService();
  const navigate = useNavigate();
  const [phoneTypes, setPhoneTypes] = useState<IServerResponse<{ id: number; type_description: string; active: boolean; }> | null>(null);

  useEffect(() => {
    getPhoneTypes({
      fn: setPhoneTypes,
    });
  }, [getPhoneTypes]);

  const redirectCreate = useCallback((event?: { preventDefault: Function }) => {
    if (event?.preventDefault) {
      event.preventDefault();
    }
    
    navigate('/config/users/create');
  }, [navigate]);

  const redirectEdit = useCallback((id: number, event?: { preventDefault: Function }) => {
    if (event?.preventDefault) {
      event.preventDefault();
    }

    navigate(`/config/users/edit/${id}`);
  }, [navigate]);

  const redirectDelete = useCallback((id: string, event?: { preventDefault: Function }) => {
    if (event?.preventDefault) {
      event.preventDefault();
    }

    _delete({
      id, 
      fn: () => navigate('/config/users', { replace: true }),
    });
  }, [navigate, _delete]);


  return (
    <UsersContext.Provider value={{ phoneTypes, redirectCreate, redirectEdit, redirectDelete }}>
      <Routes>
        <Route
          path="/"
          element={<Table />}
        />
        <Route
          path="/create"
          element={<Form type='create' />}
        />
        <Route
          path="/edit/:id"
          element={<Form type='edit' />}
        />
        <Route
          path="*"
          element={<Navigate to="/config/users" />}
        />
      </Routes>
    </UsersContext.Provider>
  );
}

export function useUsersContext() {
  const context = useContext(UsersContext);
  if (!context) {
    throw new Error('useUsersContext() must be used within a UsersContext provider.');
  }
  return context;
}