import React, { useEffect, useCallback, useMemo, useState } from "react";
import { withTranslation, TFunction } from "react-i18next";
import Select, { SingleValue } from "react-select";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { IServerResponse, Role, User } from "../../../interfaces";
import useUsersService from "../../../services/users.service";
import useRoleService from "../../../services/role.service";


interface MemberManagerProps {
    children?: React.ReactNode;
    Role: Role.IRole;
    t: TFunction;
}

const MemberManager = ({ Role, t }: MemberManagerProps) => {
    const { getAllActiveUsers } = useUsersService();
    const { getUsersFromRole, addUserToRole, removeUserFromRole } = useRoleService();
    const [users, setUsers] = useState<User.IUser[]>([]);
    const [members, setMembers] = useState<User.IUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<User.IUser | null>(null);
    const [selectedValue, setSelectedValue] = useState<SingleValue<{ value: string; label: string; }> | null>(null);

    const selectOptions = useMemo(() => {
        return users.map(u => ({
            value: u.id,
            label: u.username,
        })).filter(u => !members.some(m => m.id === u.value));
    }, [users, members]);

    const reloadMembers = useCallback(() => {
        getUsersFromRole<IServerResponse<User.IUser>>({
            params: {
                roleId: Role.id,
            },
        })
            .then(r => r.data)
            .then(setMembers)
            .catch((e: Error) => {
                setMembers([]);
                console.error(e);
            });
    }, [Role.id, getUsersFromRole]);

    const resetUsers = useCallback(() => {
        getAllActiveUsers<IServerResponse<User.IUser>>({})
            .then(r => r.data)
            .then(setUsers)
            .catch((e: Error) => {
                setUsers([]);
                console.error(e);
            });
    }, [getAllActiveUsers]);

    useEffect(() => {
        reloadMembers();
        resetUsers();
    }, [Role.id, reloadMembers, resetUsers]);

    const handleSelectChange = useCallback((selected: SingleValue<{ value: string; label: string; }>) => {
        setSelectedUser(users.find(u => u.id === selected?.value) || null);
        setSelectedValue(selected);
    }, [users]);

    const handleAddMember = useCallback(() => {
        if (selectedUser) {
            addUserToRole<IServerResponse<User.IUser>>({
                params: {
                    roleId: Role.id,
                    userId: selectedUser.id,
                },
            })
                .then(r => r.data)
                .then(data => {
                    setSelectedValue(null);
                    setSelectedUser(null);
                    reloadMembers();
                    resetUsers();
                })
                .catch((e: Error) => {
                    console.error(e);
                });
        }
    }, [Role.id, selectedUser, addUserToRole, reloadMembers, resetUsers]);

    const handleRemoveMember = useCallback((member: User.IUser) => {
        removeUserFromRole<IServerResponse<User.IUser>>({
            params: {
                roleId: Role.id,
                userId: member.id,
            },
        })
            .then(r => r.data)
            .then(data => {
                setSelectedValue(null);
                setSelectedUser(null);
                reloadMembers();
                resetUsers();
            })
            .catch((e: Error) => {
                console.error(e);
            });
    }, [Role.id, removeUserFromRole, reloadMembers, resetUsers]);

    return (
        <Container fluid className="p-4">
            <Row className="p-1">
                <Col xxl={10} xl={9} lg={8} md={7} sm={12} xs={12}>
                    <Select
                        className="w-100"
                        value={selectedValue}
                        options={selectOptions}
                        onChange={handleSelectChange}
                        placeholder={t("roles.select_field")}
                    />
                </Col>
                <Col xxl={2} xl={3} lg={4} md={5} sm={12} xs={12}>
                    <Button
                        variant="primary"
                        className="w-100"
                        onClick={handleAddMember}
                        children={t("label.action.add", { ns: "application.misc" })}
                    />
                </Col>
            </Row>
            <Row className="p-1">
                <ListGroup
                    as="ol"
                >
                    {
                        members.map(
                            /** Create list item with .username, .email & delete button */
                            (m: User.IUser) => (
                                <ListGroup.Item
                                    className="mt-2"
                                    key={m.id}
                                    as="li"
                                >
                                    <Row>
                                        <Col xxl={10} xl={9} lg={8} md={7} sm={12} xs={12}>
                                            <div>
                                                <div className="fw-bold">{m.username}</div>
                                                <div>{m.email}</div>
                                            </div>
                                        </Col>
                                        <Col xxl={2} xl={3} lg={4} md={5} sm={12} xs={12} className="mt-2">
                                            <Button
                                                variant="danger"
                                                className="w-100"
                                                onClick={() => handleRemoveMember(m)}
                                                children={t("label.action.remove", { ns: "application.misc" })}
                                            />
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            )
                        )
                    }
                </ListGroup>
            </Row>
        </Container>
    );
}

export default withTranslation()(MemberManager);