import { useCallback, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { activate, inactivate, setState } from "../reducers/alert";
import { unauthorize } from "../reducers/auth";

const HttpHandler = () => {
  const [error, setError] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  const resolveErrors = useCallback(
    async (response: Response, defaultMessage: string) => {
      let messages: string[];
      let data: any;

      try {
        data = await response.json();
        messages = data.errors;
      } catch (e) {
        messages = [
          defaultMessage,
          `Fallback: ${(response.body || response.status).toString()}`,
        ];
      }

      return messages;
    },
    []
  );

  const sendRequest = useCallback(
    async (requestConfig, applyData) => {
      let timeout: NodeJS.Timeout;
      let errors: string[];
      let message: string;
      let data: any;

      setIsLoading(true);
      const response: Response = await fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : "GET",
        body: requestConfig.body
          ? typeof requestConfig.body === "object"
            ? JSON.stringify(requestConfig.body)
            : requestConfig.body
          : null,
        headers: {
          "Content-Type": "application/json",
          ...requestConfig?.headers,
        },
      });

      if (response.ok) {
        data = await response.json();
        setIsLoading(false);
        applyData(data);
        return data;
      }

      switch (response.status) {
        case 400:
          message = "network.bad_request";
          errors = await resolveErrors(response, "network.bad_request");
          break;
        case 401:
          message = "network.unauthorized";
          errors = await resolveErrors(response, "network.unauthorized");
          dispatch(unauthorize(true));
          break;
        case 403:
          message = "network.forbidden";
          errors = await resolveErrors(response, "network.forbidden");
          break;
        case 404:
          message = "network.not_found";
          errors = await resolveErrors(response, "network.not_found");
          break;
        case 500:
          message = "network.internal_server_error";
          errors = await resolveErrors(response, "network.internal_server_error");
          break;
        default:
          message = "network.unknown_error";
          errors = await resolveErrors(response, "network.unknown_error");
          break;
      }

      dispatch(inactivate(true));
      dispatch(
        setState({
          message: message,
          type: response.type,
          errors: errors,
          status: response.status,
        })
      );

      dispatch(activate(true));
      timeout = setTimeout(() => {
        dispatch(inactivate(true));
      }, 3000);

      return async () => {
        clearTimeout(timeout);
        dispatch(inactivate(true));
        try {
          data = await response.json();
          setError(data);
          return data;
        } catch (e) {
          setError(response);
          return response;
        }
      };
    },
    [dispatch, resolveErrors]
  );
  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default HttpHandler;
