import React, { useReducer, useEffect, useState, useCallback } from "react";
import classes from "./table.module.css";
import { Table as TabletRS, Column, HeaderCell, Cell } from "rsuite-table";
import Pagination from "rsuite/Pagination";
import "rsuite-table/dist/css/rsuite-table.css";
import "rsuite/dist/rsuite.css";

import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "./../../../hook/useLocalStorage";
import { useTranslation } from "react-i18next";

import {
  IResponse,
  IView,
  ITableLayout,
  ChildrenGroupTable,
  GroupTable,
} from "../../../interfaces";
import RightClickMenu from "../Menu/RightClickMenu";

import useViewService from "../../../services/view.service";
import { useAppDispatch } from "../../../app/hooks";
import {
  activate,
  inactivated,
} from "../../../reducers/errorHttp/successHttpSlice";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { ButtonGroup } from "react-bootstrap";

/// Este objeto recibe: loadingTable, loading, getRows, getPage, handleFilter, fields, response and changePage.

const GeneralTable = (props: any, ref: any) => {
  const dispatch = useAppDispatch();
  const { storeValue } = useLocalStorage();
  const { t } = useTranslation();
  const { getAll: getAllEntityViews, createOne: createEntityView } =
    useViewService();

  // ? View data.
  const [fullQueryString, setFullQueryString] = useState<string>("");
  const [groupingHandler, setGroupingHandler] = useState<GroupTable>({
    attrGroup: "",
    isTableTree: false,
    totalizers: [],
  });
  const [viewList, setViewList] = useState<IView[]>([]);
  const [currentView, dispatchCurrentView] = useReducer(
    (state: any, data: any): any => ({ ...state, ...data }),
    {
      row_id: 0,
      name: "",
      description: "",
      entity: props.tableName ?? "",
      fields: [],
      filters: [],
      relationships: [],
      grouping: {},
      sorting: [],
      search: "",
    }
  );

  // ? Table properties.
  const [currentPage, setCurrentPage] = useState(1);
  const [state, setState] = useState<any>({
    columns: [],
    rows: [],
  });
  const location: any = useLocation();
  const navigate = useNavigate();
  //Table grouping
  const [attrTable, setAttrTable] = useState<string>("0");
  const [attrLabel, setAttrLabel] = useState<string>("");
  const [typeAttrTable, setTypeAttrTable] = useState<string>("");
  const [tableTree, setTableTree] = useState<boolean | undefined>(undefined);
  const [rowKey, setRowKey] = useState<string | undefined>("row_id");
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [totalizerOperations, setTotalizerOperations] = useState<
    ChildrenGroupTable[]
  >([]);
  const [attrMain, setAttrMain] = useState<string>("");

  let countPerPage = props.response?.pagination?.limit ?? 10;

  const createTableData = (fields: IResponse, response: IResponse) => {
    if (fields && response) {
      let columns = [];
      const rows = response.data;

      // ? Create columns for DataTable
      if (fields?.data[0]) {
        for (let columnName of Reflect.ownKeys(fields.data[0])) {
          columns.push({
            selector: (row: any) => row[columnName],
            name: t(columnName.toString()),
            sortable: true,
            omit: false,
            attrName: columnName,
            type: "",
          });
        }
      }

      setState({
        columns,
        rows,
      });
    }
  };
  useEffect(() => {
    createTableData(props.fields, props.response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, props.response, props.fields]);

  const headElements = (
    <Row
      style={{
        position: "relative",
        zIndex: 15,
      }}
    >
      <Col xxl={3} xl={3} lg={3} md={12} sm={12} xs={12} className="mb-2">
        <h3>{t(capitalizeFirstLetter(props.tableName ?? ""))}</h3>
      </Col>
    </Row>
  );

  const activateTableTree = useCallback(
    async (activeData: string) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-expressions
      await props.getPage({
        entity: props.response.tableName,
        filter: fullQueryString,
        limit: 5000,
        page: 1,
      });

      setTableTree(true);
      setTableLoading(true);
      setAttrMain(activeData);
    },
    //eslint-disable-next-line @typescript-eslint/no-unused-expressions
    [props, fullQueryString]
  );

  useEffect(() => {
    changeDataTable(state.rows);
  }, [state.columns, attrMain]);

  const changeDataTable = (nextData: any) => {
    if (attrTable !== "0" && attrTable !== "" && tableTree === true) {
      const columnRow = state.columns.filter(
        (row: any) => row.attrName === attrTable
      );
      const typeOfData = columnRow[0].type;
      let dataTree = [...nextData];
      dataTree = orderArray(dataTree, typeOfData);
      let dataLabel: string[] = [dataTree[0][attrTable]];

      for (let xi = 1; xi < dataTree.length; xi++) {
        if (dataTree[xi][attrTable] !== dataTree[xi - 1][attrTable])
          dataLabel.push(dataTree[xi][attrTable]);
      }
      let newArrayOfData: any[] = [];
      let x: number = 10000;
      for (const value of dataLabel) {
        const array = dataTree.filter((row) => row[attrTable] === value);

        newArrayOfData.push({
          id: x,
          [`${attrTable}`]: value,
          children: [...array],
        });
        x++;
      }
      for (const row of groupingHandler.totalizers) {
        newArrayOfData = operationApplyTable(
          row.operation,
          row.attrName,
          row.typeOfData,
          newArrayOfData
        )!;
      }

      setRowKey("id");
      setTableLoading(false);
      setState((prevState: any) => {
        let columnsOld: any[] = prevState.columns;
        let index = columnsOld.findIndex(
          (row: any) => row.attrName === attrMain
        );
        columnsOld[index]["colSpan"] = columnsOld.length;
        columnsOld = array_move(columnsOld, index, 0);
        return {
          columns: columnsOld,
          rows: newArrayOfData,
        };
      });
    }
  };

  useEffect(() => {
    try {
      const groupingData: GroupTable = {
        attrGroup: "",
        isTableTree: false,
        totalizers: [],
      };
      const queryStrings: any = {
        entity: props.tableName ?? "",
        relationships: "&",
        grouping: "&",
        filters: "&",
        sorting: "&",
        fields: "&",
        search: "&",
      };

      const data: any = {
        entity: props.tableName ?? "",
        relationships: currentView.relationships,
        filters: currentView.filters,
        sorting: currentView.sorting,
        fields: currentView.fields,
        search: currentView.search,
        grouping: currentView.grouping,
      };

      const table: ITableLayout = data as ITableLayout;

      for (const key in table) {
        switch (key) {
          case "filters":
            queryStrings.filters += table[key]
              .map((item: any) =>
                decodeURIComponent(
                  new URLSearchParams({
                    "key[]": item.field,
                    "operator[]": item.operator,
                    "value[]": item.value,
                  }).toString()
                )
              )
              .join("&");
            break;
          case "relationships":
            queryStrings.relationships += table[key]
              .map((item: any) =>
                decodeURIComponent(
                  new URLSearchParams({
                    "relationFilter[]": item.relation,
                    "relationValue[]": item.value,
                  }).toString()
                )
              )
              .join("&");
            break;
          case "fields":
            queryStrings.fields += table[key]
              .map((item: any) =>
                decodeURIComponent(
                  new URLSearchParams({
                    "fields[]":
                      item.indexOf("data_") === 0 ? item : `data_${item}`,
                  }).toString()
                )
              )
              .join("&");
            break;
          case "sorting":
            queryStrings.sorting += table[key]
              .map((item: any) =>
                decodeURIComponent(
                  new URLSearchParams({
                    "sort_field[]": item.field,
                    "sort_direction[]": item.direction,
                  }).toString()
                )
              )
              .join("&");
            break;
          case "grouping":
            Object.assign(groupingData, table[key]);
            break;
          case "search":
            if (table[key].length > 0) {
              queryStrings.search += decodeURIComponent(
                new URLSearchParams({
                  search: table[key],
                }).toString()
              );
            }
            break;
          case "entity":
            break;
          default:
            break;
        }
      }

      // check if the fields query string contains any field
      if (queryStrings.fields.indexOf("fields[]") !== -1) {
        queryStrings.filters += table.filters.map((item: any) =>
          "&".concat(
            decodeURIComponent(
              new URLSearchParams({
                "fields[]":
                  item.field.indexOf("data_") === 0
                    ? item.field
                    : `data_${item.field}`,
              }).toString()
            )
          )
        );

        queryStrings.sorting += table.sorting.map((item: any) =>
          "&".concat(
            decodeURIComponent(
              new URLSearchParams({
                "fields[]":
                  item.field.indexOf("data_") === 0
                    ? item.field
                    : `data_${item.field}`,
              }).toString()
            )
          )
        );
      }

      delete queryStrings.entity;

      // ? Convert the values to a query string and set state.
      const query = decodeURIComponent(
        "&".concat(
          new URLSearchParams(Object.values(queryStrings).join("&")).toString()
        )
      );

      setFullQueryString(query);

      let diferentState =
        groupingData.attrGroup === currentView.grouping.attrGroup &&
        groupingData.isTableTree === currentView.grouping.isTableTree &&
        groupingData.totalizers === currentView.grouping.totalizers;
      if (groupingData.attrGroup !== "" && diferentState) {
        setGroupingHandler(groupingData);
      }
    } catch (error: unknown) {
    }
  }, [currentView, props.tableName ?? ""]);

  useEffect(() => {
    props.getPage({
      filter: fullQueryString,
      page: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullQueryString]);

  useEffect(() => {
    if (groupingHandler.attrGroup && groupingHandler.attrGroup !== "") {
      setAttrTable(groupingHandler.attrGroup);
      activateTableTree(groupingHandler.attrGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupingHandler]);

  useEffect(() => {
    const filter = [
      new URLSearchParams({
        type: "Entity",
      }),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tableName ?? ""]);

  const handleCustomViewSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    viewNameInput: string,
    viewDescriptionInput: string
  ) => {
    e.preventDefault();
    dispatchCurrentView({
      name: viewNameInput,
      description: viewDescriptionInput,
    });
    let dataGroupBy: GroupTable = {
      attrGroup: attrMain,
      isTableTree: true,
      totalizers: totalizerOperations,
    };
    const view: IView = {
      active: true,
      shareable: false,
      name: viewNameInput,
      description: viewDescriptionInput,
      view_type: "Entity",
      view_data: {
        columns: 1,
        widgets: [
          {
            type: "TableLayout",
            data: {
              entity: currentView.entity,
              fields: currentView.fields,
              filters: currentView.filters,
              relationships: currentView.relationships,
              sorting: currentView.sorting,
              grouping: dataGroupBy,
              search: currentView.search,
            },
          },
        ],
      },
    };
    function callback(response: IResponse): void {
      dispatch(activate({ status: true, name: "View" }));
      setTimeout(() => {
        dispatch(inactivated());
      }, 3000);

      const filter = [
        new URLSearchParams({
          type: "Entity",
        }),
      ];

      getAllEntityViews({
        entity: props.tableName ?? "",
        callback: (response: IResponse): void => setViewList(response.data),
        filter,
      });
    }

    createEntityView({
      entity: props.tableName ?? "",
      data: view,
      callback,
    });
  };

  const handleApplyView = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetDataTable();
    const view: IView | undefined = viewList.find(
      (item: IView) => item.id === currentView.id
    );
    if (view) {
      const { data } = view.view_data.widgets[0];
      const table: ITableLayout = data as ITableLayout;
      dispatchCurrentView({
        id: view.id || 0,
        name: view.name || "",
        description: view.description || "",
        entity: props.tableName ?? ("" || ""),
        relationships: table.relationships || [],
        filters: table.filters || [],
        sorting: table.sorting || [],
        fields: table.fields || [],
        grouping: table.grouping || {},
        search: table.search || "",
      });
    }
  };
  const getValuesGroupBy = (
    array: any[],
    attrValue: string,
    typeOf: string
  ): number => {
    let returnValue: number = 0;
    let xi: number;
    switch (typeOf) {
      case "summ":
        xi = 0;
        for (const row of array) {
          xi += +row[attrValue];
        }
        returnValue = xi;
        break;
      case "average":
        let x: number = 0;
        xi = 0;
        for (const row of array) {
          xi += +row[attrValue];
          x++;
        }
        returnValue = xi / x;
        break;
    }
    return returnValue;
  };

  const orderArray = useCallback(
    (array: any[], typeOfData: string): any[] => {
      return array.sort((a, b) => {
        if (typeOfData === "number") {
          if (+a[attrTable] > +b[attrTable]) {
            return 1;
          }

          if (+a[attrTable] < +b[attrTable]) {
            return -1;
          }
        } else {
          if (a[attrTable] > b[attrTable]) {
            return 1;
          }

          if (a[attrTable] < b[attrTable]) {
            return -1;
          }
        }

        return 0;
      });
    },
    [attrTable]
  );

  const onHandlerContextMenu = (
    attrTable: string,
    attrTableLabel: string,
    typeOfData: string
  ): void => {
    setAttrTable(attrTable);
    setTypeAttrTable(typeOfData);
    setAttrLabel(attrTableLabel);
  };

  const operationApplyTable = useCallback(
    (
      typeOperation: string,
      attrName: string,
      typeOfColumnData: string,
      dataTable?: any[]
    ): any[] | void => {
      // eslint-disable-next-line
      if (!dataTable || dataTable.length === 0) dataTable = [...state.rows];
      if (dataTable.length > 0 && dataTable[0].children) {
        switch (typeOperation) {
          case "TOTAL":
            for (const row of dataTable) {
              const totalRows: number = row.children.length;
              row[attrName] = `${t("total_rows")}: ${totalRows}`;
            }
            break;
          case "MAX":
            for (const row of dataTable) {
              // const MIN = dataTable[0][attrTable];
              let children = [...row.children];
              children = orderArray(children, typeOfColumnData);
              const MAX = children[children.length - 1][attrTable];
              row[attrName] = `${t("Max")}: ${MAX}`;
            }

            break;
          case "MIN":
            for (const row of dataTable) {
              let children = [...row.children];
              children = orderArray(children, typeOfColumnData);
              const MIN = children[0][attrTable];
              row[attrName] = `${t("Min")}: ${MIN}`;
            }
            break;
          case "SUM":
            for (const row of dataTable) {
              const summValue = getValuesGroupBy(
                row.children,
                attrName,
                "summ"
              );
              row[attrName] = `${t("Sum")} : ${summValue}`;
            }
            break;
          case "PROM":
            for (const row of dataTable) {
              const promValue = getValuesGroupBy(
                row.children,
                attrName,
                "average"
              );
              row[attrName] = `${t("PROM")} : ${promValue}`;
            }
            break;
        }
        setTotalizerOperations((prevState) => {
          let data = [...prevState];
          for (const row of data) {
            if (row.attrName === attrName) {
              row.attrName = attrName;
              row.typeOfData = typeOfColumnData;
              row.operation = typeOperation;
            } else {
              data.push({
                attrName: attrName,
                typeOfData: typeOfColumnData,
                operation: typeOperation,
              });
            }
          }
          if (data.length === 0)
            data.push({
              attrName: attrName,
              typeOfData: typeOfColumnData,
              operation: typeOperation,
            });
          return data;
        });
        setTableLoading(false);
        setState((prevState: any) => ({
          ...prevState,
          rows: dataTable,
        }));
        return dataTable;
      }
    },
    [attrTable, orderArray, state.rows, t]
  );

  const array_move = (
    arr: any[],
    old_index: number,
    new_index: number
  ): any[] => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  const resetDataTable = async () => {
    await props.getPage({
      entity: props.response.tableName,
      page: 1,
    });
    setTableTree(false);
    setTableLoading(false);
    setAttrMain("");
  };

  const columnsFormatted = state.columns.map((rowValue: any) => {
    const { name, attrName, type, colSpan } = rowValue;
    if (attrName !== "row_id") {
      return (
        <Column
          key={attrName}
          width={277}
          colSpan={colSpan ? colSpan : undefined}
        >
          <HeaderCell
            onClick={(e) => {
              setAttrTable(attrName);
            }}
            onContextMenu={(e) => {
              onHandlerContextMenu(attrName, name, type);
            }}
          >
            {name}
          </HeaderCell>
          <Cell
            className={classes["rs-table-cell"]}
            style={{ whiteSpace: "pre" }}
            dataKey={attrName}
          />
        </Column>
      );
    } else {
      return null;
    }
  });
  if (
    typeof props.onOpenEditModal === "function" &&
    typeof props.onOpenDeleteModal === "function"
  ) {
    columnsFormatted.push(
      <Column key={"actions"} width={277} fixed="right">
        <HeaderCell
          onClick={(e) => {
            setAttrTable("actions");
          }}
          onContextMenu={(e) => {
            onHandlerContextMenu("actions", "actions", "");
          }}
        >
          {t("actions")}
        </HeaderCell>
        <Cell>
          {(rowData) => {
            function handleEdit() {
              props.onOpenEditModal(rowData.id);
            }
            function handleDelete() {
              props.onOpenDeleteModal(rowData.id);
            }
            return (
              <span>
                <Button variant="success" type="submit" onClick={handleEdit}>
                  {t("edit")}
                </Button>
                {" "}
                <Button
                  variant="success"
                  type="submit"
                  onClick={handleDelete}
                >
                {t("remove")}  
                </Button>
              </span>
            );
          }}
        </Cell>
      </Column>
    );
        }

  return (
    <React.Fragment>
      <Container fluid className="p-4">
        <Row>
          <Col className="d-flex justify-content-end">
            <ButtonGroup>
              {props.fields && (
                <React.Fragment>
                  <Button
                    className="m-1"
                    variant="primary"
                    onClick={props.handleCreateClick}
                  >
                    {t("New")}
                  </Button>
                </React.Fragment>
              )}
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            {props.fields.data.length > 0 && props.response.status !== 0 ? (
              <React.Fragment>
                <Container fluid className="mt-4">
                  {state ? (
                    <>
                    {
                    props.response.status === 200 && (
                      <Container fluid className="p-3">
                        {headElements}
                        <Row className="d-flex flex-sm-column">
                          <Col xxl={12} xl={12} lg={8} md={12} sm={12} xs={12}>
                            {state.columns &&
                              state.columns.length > 0 &&
                              state.rows.length > 0 && (
                                <div>
                                  <RightClickMenu
                                    inTree={tableTree}
                                    attrName={attrTable}
                                    typeOfData={typeAttrTable}
                                    attrLabel={attrLabel}
                                    onClickData={activateTableTree}
                                    onSetOperation={operationApplyTable}
                                  />
                                  <TabletRS
                                    data={state.rows}
                                    wordWrap
                                    isTree={tableTree}
                                    shouldUpdateScroll={false}
                                    rowKey={rowKey}
                                    loading={tableLoading}
                                    height={510}
                                    bordered={true}
                                    onRowClick={(row: any) => {
                                      if (row["row_id"] !== undefined) {
                                        storeValue({
                                          key: "singleEntity",
                                          initialValue: row,
                                        });
                                        navigate(
                                          `/${props.tableName ?? ""}/show/${
                                            row["row_id"]
                                          }`
                                        );
                                      }
                                    }}
                                  >
                                    {columnsFormatted}
                                  </TabletRS>

                                  <div style={{ padding: 20 }}>
                                    <Pagination
                                      prev
                                      next
                                      first
                                      last
                                      ellipsis
                                      boundaryLinks
                                      maxButtons={5}
                                      size="xs"
                                      layout={["total", "-", "pager", "skip"]}
                                      activePage={currentPage}
                                      total={
                                        props.response.pagination.recordsTotal
                                      }
                                      limit={+countPerPage}
                                      locale={{
                                        skip: t("go_to") + " {0}",
                                        total: t("total_rows") + " {0}",
                                      }}
                                      onChangePage={(page) => {
                                        setCurrentPage(page);
                                        return props.onChangeOfPage(page);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                          </Col>
                        </Row>
                      </Container>
                    )}
                    {
                      props.response.error && (
                        <Alert variant="danger">
                        {props.response.data?.message
                          ? props.response.data.message
                          : "aaaaaaaaaaaa"}
                      </Alert>
                    )
                  }
                    </>
                  ) : (
                    <Container className="d-flex text-center justify-content-center">
                      <Spinner animation="border" role="status">
                        <span>Loading...</span>
                      </Spinner>
                    </Container>
                  )}
                </Container>
              </React.Fragment>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GeneralTable;
