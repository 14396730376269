import React, { useEffect, useState, useMemo } from "react";
import DeleteModal from "../../DeleteModal";
import useUserService from "../../../../../services/user.service";
import GeneralTable from "../../../../UI/Input/GeneralTable";
import { useLocation, useNavigate } from "react-router-dom";
import usePersonService from "../../../../../services/person.service";
import { useTranslation } from "react-i18next";
import Alert from "../Domains/Alert";

type phoneType = { id: number; phone_number: string; type_description: string };

function Person() {
  const { t } = useTranslation();
  const { getMany: getPerson, getPhoneTypes, _delete } = usePersonService();
  const [person, setPerson] = useState({
    data: [],
    tableName: t("Contacts"),
    error: false,
    errors: [],
    message: "",
    pagination: {},
    recordsFiltered: 0,
    recordsTotal: 0,
    status: 0,
  });
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [phoneTypesList, setphoneTypesList] = useState([]);
  const [phones, setPhones] = useState([]);
  const location: any = useLocation();
  const navigate = useNavigate();

  const setPersonData = (data: any) => {
    if (data.data.length !== 0) {
      const phones: any = data.data.map((item: any) => {
        return {
          id: item.id.toString(),
          phones: item.phones,
        };
      });
      data.data = data.data.map((item: any) => {
        return {
          id: item.id.toString(),

          date_modified: item?.date_modified
            ? item.date_modified.toString().replace(/T|Z/, " ")
            : "",
          modified_user_id: item?.modified_user_id
            ? item.modified_user_id.toString()
            : "",
          created_by: item?.created_by ? item.created_by.toString() : "",

          date_deleted: item?.date_deleted
            ? item.date_deleted.toString().replace(/T|Z/, " ")
            : "",
          first_name: item?.first_name ? item.first_name.toString() : "",
          last_name: item?.last_name ? item.last_name.toString() : "",
          salutation: item?.salutation ? item.salutation.toString() : "",
          title: item?.title ? item.title.toString() : "",
          description: item?.description ? item.description.toString() : "",
          photo: item?.photo ? item.photo.toString() : "",
          department: item?.department ? item.department.toString() : "",
          date_entered: item?.date_entered
            ? item.date_entered.toString().replace(/T|Z/, " ")
            : "",
          do_not_call: item.do_not_call ?? false,
          primary_address_street: item?.primary_address_street
            ? item.primary_address_street.toString()
            : "",
          primary_address_city: item?.primary_address_city
            ? item.primary_address_city.toString()
            : "",
          primary_address_state: item?.primary_address_state
            ? item.primary_address_state.toString()
            : "",
          primary_address_postalcode: item?.primary_address_postalcode
            ? item.primary_address_postalcode.toString()
            : "",
          primary_address_country: item?.primary_address_country
            ? item.primary_address_country.toString()
            : "",
          alt_address_street: item?.alt_address_street
            ? item.alt_address_street.toString()
            : "",
          alt_address_city: item?.alt_address_city
            ? item.alt_address_city.toString()
            : "",
          alt_address_state: item?.alt_address_state
            ? item.alt_address_state.toString()
            : "",
          alt_address_postalcode: item?.alt_address_postalcode
            ? item.alt_address_postalcode.toString()
            : "",
          alt_address_country: item.alt_address_country,
          assistant: item.assistant,
          assistant_phone: item.assistant_phone,
          active: item.active.toString(),
          phone_home:
            item.phones
              .find((phone: phoneType) => phone?.type_description === "home")
              ?.phone_number.toLowerCase() === "null"
              ? ""
              : item.phones.find(
                  (phone: phoneType) => phone?.type_description === "home"
                )?.phone_number,
          phone_mobile:
            item.phones
              .find((phone: phoneType) => phone?.type_description === "mobile")
              ?.phone_number.toLowerCase() === "null"
              ? ""
              : item.phones.find(
                  (phone: phoneType) => phone?.type_description === "mobile"
                )?.phone_number,
          phone_work:
            item.phones
              .find((phone: phoneType) => phone?.type_description === "work")
              ?.phone_number.toLowerCase() === "null"
              ? ""
              : item.phones.find(
                  (phone: phoneType) => phone?.type_description === "work"
                )?.phone_number,
          phone_other:
            item.phones
              .find((phone: phoneType) => phone?.type_description === "other")
              ?.phone_number.toLowerCase() === "null"
              ? ""
              : item.phones.find(
                  (phone: phoneType) => phone?.type_description === "other"
                )?.phone_number,
          phone_fax:
            item.phones
              .find((phone: phoneType) => phone?.type_description === "fax")
              ?.phone_number.toLowerCase() === "null"
              ? ""
              : item.phones.find(
                  (phone: phoneType) => phone?.type_description === "fax"
                )?.phone_number,
        };
      });
      setPhones(phones);
      setPerson(data);
    }
  };

  function setPhonesData(data: any) {
    setphoneTypesList(
      data.data.map((item: any) => {
        return { id: item.id, value: item.type_description };
      })
    );
  }

  useEffect(() => {
    getPerson({
      page: currentPage,
      fn: setPersonData,
      limit: 10,
    });
    getPhoneTypes({
      fn: setPhonesData,
    });
  }, []);

  const { getAll } = useUserService();
  const getData = ({ page = 1, limit = 10, filter = [] }: any) => {
    getAll({
      fn: setPerson,
      page,
      limit,
      filter,
    });
  };
  function openEditModal(id: any) {
    navigate("/admin/edit", {
      state: {
        currentData: person.data.find((item: any) => item.id === id),
        phoneTypesList,
        phones: (phones?.find((item: any) => item.id === id) ?? { phones: [] })
          .phones,
      },
    });
  }

  function handleCloseDelete() {
    setIsDeleteModalShowing(false);
  }

  function openDeleteModal(id: any) {
    setIsDeleteModalShowing(true);
    setIdToDelete(id);
  }

  function handleOnContinueDelete(id: any) {
    _delete({
      fn: (data: any) => {
        if (data.status === 200) {
          getPerson({
            page: currentPage,
            fn: setPersonData,
            limit: 10,
          });
          setIsDeleteModalShowing(false);
          return;
        }
      },
      id,
    });
  }

  function handleChangeOfPage(page: number) {
    setCurrentPage(page);
  }
  useEffect(() => {
    getPerson({
      page: currentPage,
      fn: setPersonData,
      limit: 10,
    });
  }, [currentPage]);

  function handleCreateClick() {
    navigate("/admin/create", { state: { phoneTypesList } });
  }
  const dataForTable = person.data.map((item: any) => {
    return {
      first_name: item?.first_name,
      last_name: item?.last_name,
      salutation: item?.salutation,
      title: item?.title,
      description: item?.description,
    };
  });
  function returnAlert(status: number) {
    if (status === 201) {
      return (
        <Alert
          type="success"
          title={t("success")}
          description={t("person_created")}
        ></Alert>
      );
    } else if (status === 200) {
      return (
        <Alert
          type="success"
          title={t("success")}
          description={t("person_updated")}
        ></Alert>
      );
    } else if (status === 500) {
      return (
        <Alert
          type="danger"
          title={t("success")}
          description={t("something_goes_wrong")}
        ></Alert>
      );
    } else {
      return;
    }
  }
  return (
    <React.Fragment>
      <DeleteModal
        onClose={handleCloseDelete}
        handleClose={handleCloseDelete}
        isModalShowing={isDeleteModalShowing}
        onContinueDelete={handleOnContinueDelete}
        idToDelete={idToDelete}
        header={"Delete Contact"}
        tableTitle="Contact"
      />
      {returnAlert(location?.state?.status ?? 0)}

      <GeneralTable
        response={person}
        fields={{ data: dataForTable }}
        getPage={getData}
        tableName="Contacts"
        onOpenEditModal={openEditModal}
        onOpenDeleteModal={openDeleteModal}
        onChangeOfPage={handleChangeOfPage}
        handleCreateClick={handleCreateClick}
      />
    </React.Fragment>
  );
}

export default Person;
