import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import { ArrowLeft } from "react-bootstrap-icons";

import ModalForm from "../../UI/Modal/ModalForm/ModalForm";
import DymForm from "../../UI/Form/DymForm";
import useLocalStorage from "../../../hook/useLocalStorage";
import { selectDomain } from "../../../reducers/domain/domainSlice";
import { useAppSelector } from "../../../app/hooks";

import useEntityService from "./../../../services/entity.service";
import FileImportModal from "../../UI/FileImportModal/FileImportModal";
import {
  changeSpacesForUnderscore,
} from "../../../utils/utils";
import { Entity, IResponse } from "../../../interfaces";
import { EntityTable } from "src/components/UI/EntityTable";
import { capitalize, trim } from "lodash";

export default function EntityPage(props: any) {
  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);
  const { entity } = useParams();

  useLayoutEffect(() => {
    if (typeof entity === 'string') {
      setNamespace('entities.'.concat(entity));
    }
  }, [entity]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    getLayoutProperties,
    getEntityProperties,
    getFields, getAll,
  } = useEntityService();

  const [entityData, setEntityData] = useState<IResponse>();
  const [entityFields, setEntityFields] = useState<IResponse>();
  const currentDomain = useAppSelector(selectDomain);
  const [baseEntity, setBaseEntity] = useState<Entity.IBaseEntity | null>(null);
  const { storeValue } = useLocalStorage("entityFields", entityFields);

  const [show, setShow] = useState<any>(false);
  const [showImportModal, setShowImportModal] = useState<any>(false);
  const [, setLayoutProperties] = useState<any[]>([]);

  useEffect(() => {
    if (typeof entity === 'string') {
      setEntityFields(undefined);
      getFields({ fn: setEntityFields, entity });
      if (currentDomain.id) {
        setEntityData(undefined);
        getAll({ entity: changeSpacesForUnderscore(entity), fn: setEntityData });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDomain]);

  useEffect(() => {
    if (show === false && typeof entity === 'string') {
      setEntityData(undefined);
      setEntityFields(undefined);
      getFields({ fn: setEntityFields, entity });
      getAll({ entity: changeSpacesForUnderscore(entity), fn: setEntityData });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, show, currentDomain, props.language]);

  useEffect(() => {
    storeValue({ key: "entityFields", initialValue: entityFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityFields]);

  useEffect(() => {
    getLayoutProperties({
      fn: (data: any) => setLayoutProperties(data.data),
      entity,
    });
  }, [entity, getLayoutProperties]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  const titleCaption = useMemo(() => {
    if (!entity) return "";

    let value = entity;
    value = tWithEntityNamespace("label");
    value = trim(value);
    value = capitalize(value);
    value = value.concat(baseEntity?.use_adapter ? `(${baseEntity?.datasource})` : "");

    return value;
  }, [baseEntity, entity, tWithEntityNamespace]);

  useEffect(() => {
    if (!entity) return;
    getEntityProperties({ entity, fn: () => { } })
      .then(response => {
        if (response.data.length) {
          const [base] = response.data;
          setBaseEntity(base);
        }
      })
  }, [entity, getEntityProperties]);

  if (!entity) return null;
  return (
    <React.Fragment>
      <Container fluid className="p-4">
        <Row>
          <Col className="d-flex justify-content-between">
            <Button
              onClick={() => navigate(`/`)}
              variant="outline-secondary"
            >
              <ArrowLeft /> {" ".concat(t("label.action.return", { ns: "application.misc" }))}
            </Button>
            <ButtonGroup>
              {currentDomain.id && entityData && entityFields && (
                <React.Fragment>
                  <Button
                    className="m-1"
                    variant="success"
                    onClick={setShowImportModal.bind(null, true)}
                  >
                    {t("entity_table.import_from", { source: "Excel" })}
                  </Button>
                  <Button
                    className="m-1"
                    variant="primary"
                    onClick={handleShow}
                  >
                    {capitalize(
                      t("entity_table.create_entity", {
                        entity: titleCaption,
                      })
                    )}
                  </Button>
                </React.Fragment>
              )}
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            {
              entityData && entityFields
                ? <React.Fragment>
                  {
                    !currentDomain.id
                      ? <div className="pt-5" children={t("domain.select_domain", { ns: "application.misc" })} />
                      : <EntityTable entity={entity} />
                  }
                </React.Fragment>
                : <Spinner animation="border" role="status"
                  children={<span className="visually-hidden" children={t("label.loading")} />}
                />
            }
          </Col>
        </Row>
      </Container>
      <FileImportModal
        entity={entity}
        fields={entityFields}
        showModal={showImportModal}
        handleClose={() => setShowImportModal(false)}
      />
      <ModalForm
        show={show}
        onHide={handleClose}
        handleClose={handleClose}
        header={capitalize(t("create_entity.header", { entity: titleCaption }).toLowerCase())}
        children={
          <DymForm
            type="POST"
            entity={entity}
            onSubmit={handleClose}
            fetchNewData={() => handleClose()}
            setExternalErrors={function (errors: string[]) {
              throw new Error('Function not implemented.');
            }} />
        }
      />
      {/* ? TODO: Continue with the Layout Epic */}
      {/*
        <Layout
          XTypes={layoutProperties}
          Fields={entityFields?.data ?? []}
        />
      */}
    </React.Fragment>
  );
}
