import { useEffect, useState } from "react";
import {
  Dropdown as DropdownRS,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const Dropdown = (props: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [activeOption, setActiveOption] = useState(
    props?.activeOption??undefined
  );

  function toggle() {
    setDropdownOpen((prevState) => !prevState);
  }

  const dropdownItems = props.items.map((item: any) => {
    if (activeOption === item.id) {
      return (
        <DropdownItem
          active
          onClick={(e: any) => {
            setActiveOption(Number(e.target.id));
          }}
          key={item.value}
          id={item.id}
        >
          {item.value}
        </DropdownItem>
      );
    }
    return (
      <DropdownItem
        onClick={(e: any) => {
          setActiveOption(Number(e.target.id));
        }}
        key={item.value}
        id={item.id}
      >
        {item.value}
      </DropdownItem>
    );
  });
  useEffect(() => {
    if (activeOption !== undefined) props.onSelect(activeOption);
  }, [activeOption]);

  return (
    <DropdownRS isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>{props.title}</DropdownToggle>
      <DropdownMenu>{dropdownItems}</DropdownMenu>
    </DropdownRS>
  );
};

export default Dropdown;
