import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { getAlert } from "../../../reducers/alert";
import Alert from "react-bootstrap/Alert";

const AlertHttp = forwardRef((props: any, ref) => {
  const alertHandler = useAppSelector(getAlert);
  const { t } = useTranslation("application.misc");

  if (!alertHandler.active) return null;

  const { type, status, message, errors } = alertHandler;

  // ? Resolves to fetch() type of response.
  const variants: { [key: string]: string; } = {
    cors: "danger",
    error: "danger",
    opaque: "warning",
    basic: "secondary",
    default: "primary",
    opaqueredirect: "warning",
  };

  return (
    <Alert className="w-100"
      variant={variants[type]}
      style={{
        transition: "all 0.3s ease-in-out !important",
        position: "absolute",
        zIndex: "100",
      }}
      children={(
        <p>
          <strong>{status} - {t(message)}</strong>

          {
            ["danger"].includes(variants[type])
              ? " -  " +  t("label.help.something_went_wrong")
              : null
          }
          
          {" - " + errors.map(e => t(e)).join(", ")}.
        </p>
      )}
    />
  );
});

export default AlertHttp;
