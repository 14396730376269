import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import environment from "./environment";
import AppLayout from "./components/shared/AppLayout/AppLayout";
import {
  Home,
  Entity,
  AddEntity,
  SingleEntity,
  EditEntity,
  SignIn,
  SignUp,
  Config,
} from "./components/pages";
import { ProtectedRoutes } from "./components/shared/Security";
import Admin from "./components/pages/Admin/Admin";
import Person from "./components/pages/Admin/Profile/Person/Person";
import Profile from "./components/pages/Admin/Profile/Profile";

export default function router() {
  return (
    <Router basename={environment.basename}>
      <Routes>
        <Route
          caseSensitive
          path="/login"
          element={<AppLayout children={<SignIn />} />}
        />

        <Route
          caseSensitive
          path="/register"
          element={<AppLayout children={<SignUp />} />}
        />

        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <AppLayout children={<Home />} />
            </ProtectedRoutes>
          }
        />

        <Route
          caseSensitive
          path="/:entity"
          element={
            <ProtectedRoutes>
              <AppLayout children={<Entity />} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/:entity/add"
          element={
            <ProtectedRoutes>
              <AppLayout children={<AddEntity />} />
            </ProtectedRoutes>
          }
        />

        <Route
          caseSensitive
          path="/:entity/show/:id"
          element={
            <ProtectedRoutes>
              <AppLayout children={<SingleEntity />} />
            </ProtectedRoutes>
          }
        />

        <Route
          caseSensitive
          path="/:entity/edit/:id"
          element={
            <ProtectedRoutes>
              <AppLayout children={<EditEntity />} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/admin"
          element={
            <ProtectedRoutes>
              <AppLayout children={<Person />} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/admin/create"
          element={
            <ProtectedRoutes>
              <AppLayout children={<Profile />} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/admin/edit"
          element={
            <ProtectedRoutes>
              <AppLayout children={<Profile />} />
            </ProtectedRoutes>
          }
        />

        <Route
          caseSensitive
          path="/config/*"
          element={
            <ProtectedRoutes>
              <AppLayout children={<Config />} />
            </ProtectedRoutes>
          } />

          <Route
            path="*"
            element={
              <Navigate to="/" replace />
            } />
      </Routes>
    </Router>
  );
}
