import Table from "rsuite/Table";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { withTranslation, TFunction } from "react-i18next";
import { IServerResponse, Domain } from "src/interfaces";
import useRoleService from "src/services/role.service";
import { DomainsByRoleType, DomainsByRoleOptions } from "./types";
import ModalForm from "src/components/UI/Modal/ModalForm/ModalForm";
import Select, {SingleValue} from "react-select";
import useDomainsService from "src/services/domains.service";
import _ from "lodash";

interface DomainsByRoleProps {
  roleId: number;
  t: TFunction;
}

interface DeleteDomainModalProps {
  showDeleteDomainModal: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  t: TFunction;
}

interface DomainsSelectOption {
  value: {
    id: number,
  };
  label?: string;
}

const DomainsByRole = ({ roleId, t }: DomainsByRoleProps) => {
  const { getDomainsFromRole, deleteDomainFromRole, addDomainToRole } = useRoleService();
  const { getMany } = useDomainsService();
  const [currentRoleId, setCurrentRoleId] = useState(0);
  const [domainsByRole, setDomainsByRole] = useState<DomainsByRoleType[]>([]);
  const [isDeleteDomainModalOpen, setDeleteDomainModalOpen] = useState<boolean>(false);
  const [isAddDomainOpen, setAddDomainOpen] = useState<boolean>(false);
  const [domainSelected, setDomainSelected] = useState<number | DomainsSelectOption>();
  const [domainOptions, setDomainOptions] = useState<DomainsByRoleOptions[]>([]);

  useEffect(() => {
    if (currentRoleId !== roleId) {
      getDomainsFromRole<IServerResponse<Domain.IDomain[]>>({ params: { roleId }})
      .then((resp: any) => {
        if (resp?.data?.length) {
          setDomainsByRole(resp?.data);
          const options = resp?.data?.map((domain: Domain.IDomain) => ({
            label: domain.name,
            value: {
              id: domain.id
            }
          }));
          setDomainOptions(options);
        } else {
          setDomainOptions([]);
          setDomainsByRole([]);
        }
      });
      setCurrentRoleId(roleId);
    }
  }, [getDomainsFromRole, roleId, domainOptions, domainsByRole, getMany, currentRoleId]);

  useEffect(() => {
    if (isAddDomainOpen) {
      getMany({
        fn: (resp: any) => {
          if (resp?.data) {
            const options = resp?.data?.map((domain: Domain.IDomain) => ({
              label: domain.name,
              value: {
                id: domain.id
              }
            }));
            const optionsFiltered = options.filter((option: DomainsSelectOption) => !_.find(domainsByRole, { id: String(option.value.id) }));
            setDomainOptions(optionsFiltered);
          }
        }
      });
    }
  }, [isAddDomainOpen, getMany, domainsByRole]);

  const ActionsButtons = ({ rowData, dataKey, ...props }: any) => {
    const handleOnClick = () => {
      setDomainSelected(rowData.id);
      setDeleteDomainModalOpen(true);
    };
    return (
      <Table.Cell {...props} style={{padding: '8px'}}>
        <Button onClick={handleOnClick} title={t("label.action.delete", { ns: "application.misc" })} size="sm"><Trash /></Button>
      </Table.Cell>
    );
  };

  const DeleteDomainModal = ({ showDeleteDomainModal = false, handleCancel, handleConfirm, t }: DeleteDomainModalProps) => {
    const modalFooter = (
      <>
        <Button variant="tertiary" onClick={handleCancel}>{t("label.action.cancel", { ns: "application.misc" })}</Button>
        <Button onClick={handleConfirm}>{t("label.action.delete", { ns: "application.misc" })}</Button>
      </>
    );
    return (
      <ModalForm
        show={showDeleteDomainModal}
        header={t("roles.domains_delete_modal.domains_delete_modal_title")}
        handleClose={handleCancel}
        footer={modalFooter}
      >
        {t("roles.domains_delete_modal.domains_delete_modal_message")}
      </ModalForm>
    );
  };

  const handleAddDomain = () => {
    setAddDomainOpen(true);
  };

  const handleModalsCancel = () => {
    setDeleteDomainModalOpen(false);
    setAddDomainOpen(false);
  };

  const handleDeleteDomainConfirm = useCallback(() => {
    deleteDomainFromRole({ params: { roleId, domainId: domainSelected }})
      .then((resp: any) => {
        setDomainsByRole(resp?.data?.length ? resp.data : []);
        setDeleteDomainModalOpen(false);
      });
  }, [deleteDomainFromRole, roleId, domainSelected]);

  const handleAddDomainConfirm = useCallback((domainId) => {
    addDomainToRole({ params: { roleId, domainId }})
      .then((resp: any) => {
        setDomainsByRole(resp?.data?.length ? resp.data : []);
        setAddDomainOpen(false);
      });
  }, [addDomainToRole, roleId]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={6}><h2>{t("domains.domains_title")}</h2></Col>
        <Col xs={12} sm={6} className="d-flex justify-content-start justify-content-sm-end"><Button onClick={handleAddDomain}>{t("roles.add_domains")}</Button></Col>
      </Row>
      { isAddDomainOpen && (
        <Row>
          <Col>
            <Select
              className="w-70 mb-2 mt-2"
              options={domainOptions}
              onChange={(item: SingleValue<DomainsByRoleOptions> = { label: "", value: { id: 0 }}) => {
                if (item?.value?.id) {
                  handleAddDomainConfirm(item.value.id);
                }
              }}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          { domainsByRole?.length ? (
            <Table data={domainsByRole} autoHeight className="w-100 mb-2 mt-2">
              <Table.Column align="center" fixed>
                <Table.HeaderCell>{t("roles.domains_table.id")}</Table.HeaderCell>
                <Table.Cell dataKey="id" />
              </Table.Column>
              <Table.Column align="left" width={250}>
                <Table.HeaderCell>{t("roles.domains_table.domain_name")}</Table.HeaderCell>
                <Table.Cell dataKey="name" />
              </Table.Column>
              <Table.Column align="left" width={450}>
                <Table.HeaderCell>{t("roles.domains_table.domain_description")}</Table.HeaderCell>
                <Table.Cell dataKey="description" />
              </Table.Column>
              <Table.Column fixed="right" width={250}>
                <Table.HeaderCell>{t("roles.domains_table.actions")}</Table.HeaderCell>
                <ActionsButtons dataKey="id" />
              </Table.Column>
            </Table>
          ) : (
            <div>{t("roles.domains_table.no_data")}</div>
          )}
        </Col>
      </Row>
      <DeleteDomainModal
        showDeleteDomainModal={isDeleteDomainModalOpen}
        handleCancel={handleModalsCancel}
        handleConfirm={handleDeleteDomainConfirm}
        t={t}
      />
    </Container>
  )
};

export default withTranslation()(DomainsByRole);
