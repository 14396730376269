import environment from "../environment";
import HttpHandler from "../hook/http-handler";
import { useCallback } from "react";

// ? --- React Redux Selectors ---
import { useAppSelector } from "../app/hooks";
import { selectDomain } from "../reducers/domain/domainSlice";
import { getToken } from "../reducers/auth";

import { IServerResponse, Entity, Field, RelationshipKey } from "../interfaces";

interface IParams {
  id?: number | string;
  fn: Function;
  entity: string;
  data?: Entity.IEntity | any;
  relationship?: string;
  limit?: number;
  page?: number;
  filter?: string;
  //testing 318
  related?: string;
  //end testing 318
  errorHandler?: Function;
}

interface IGetAllParams {
  fn?: Function;
  limit?: number;
  page?: number;
  filter?: string;
  data?: Object;
  id?: number | string;
}

const API_Entities_Endpoint = environment.apiUrl.concat("/", "entities");

export default function useEntityService() {
  const currentDomain = useAppSelector(selectDomain);
  const credentials = useAppSelector(getToken);
  const { sendRequest } = HttpHandler();

  const getAll = useCallback(
    function <IType = IServerResponse<Entity.IEntity>>(
      {
        entity,
        limit,
        page,
        filter,
        errorHandler = (error: any) => console.log(error),
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const query = new URLSearchParams({
              limit: limit ? limit.toString() : "10",
              page: page ? page.toString() : "1",
              domain: currentDomain.id,
            })
              .toString()
              .concat(filter || "");

            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat(
                  "/",
                  entity,
                  "?",
                  query,
                  "&relationship[]=all"
                ),
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const getAllWithSingleRelationship = useCallback(
    async ({
      fn,
      entity,
      relationship,
      limit = 10,
      page = 1,
      filter = "",
    }: IParams) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${API_Entities_Endpoint}/${entity}?relationship[]=${relationship}&page=${page}&limit=${
              limit || 10
            }${filter || ""}`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getAllWithRelationships = useCallback(
    ({ fn, entity }: { fn: Function; entity: string }) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${API_Entities_Endpoint}/${entity}/relationships`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getSingleRecordWithRelationships = useCallback(
    function <IType = IServerResponse<Entity.IEntity>>(
      {
        entity,
        id,
        errorHandler = (error: any) => console.log(error),
        //testing 318
        related,
        //end testing 318
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat(
                  "/",
                  entity,
                  "/",
                  typeof id === "number" ? id?.toString() : id || "0",
                  "?relationship[]=all"
                ),
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const getFields = useCallback(
    function <IType = IServerResponse<Field>>(
      {
        entity,
        errorHandler = (error: any) => console.log(error),
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat("/", entity, "/", "fields"),
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const getEntityProperties = useCallback(
    function <IType = IServerResponse<Entity.IBaseEntity>>(
      {
        entity,
        errorHandler = (error: any) => console.log(error),
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat(
                  "/",
                  entity,
                  "/",
                  "properties"
                ),
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const getRelationshipsKeys = useCallback(
    function <IType = IServerResponse<RelationshipKey.IKey>>(
      {
        entity,
        errorHandler = (error: any) => console.log(error),
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat(
                  "/",
                  entity,
                  "/",
                  "relationships",
                  "/",
                  "keys"
                ),
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const getLayoutProperties = useCallback(
    ({ fn, entity }: { fn: Function; entity: any }) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${API_Entities_Endpoint}/${entity}/layout_properties`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
            },
          },
          fn
        );
      }
    },
    [credentials, sendRequest]
  );

  const createRelationship = useCallback(
    function <IType = IServerResponse<Entity.IEntity>>(
      {
        id,
        data,
        entity,
        errorHandler = (error: any) => console.log(error),
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat(
                  "/",
                  entity,
                  "/",
                  id?.toString() || "0"
                ),
                method: "PUT",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  "Content-Type": "application/json",
                  domain: currentDomain.id,
                },
                body: data,
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const validateRecord = useCallback(
    function <IType = IServerResponse<Entity.IEntity>>(
      {
        entity,
        data,
        errorHandler = (error: any) => console.log(error),
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat("/", entity, "/1/validate"),
                method: "PUT",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  "Content-Type": "application/json",
                  domain: currentDomain.id,
                },
                body: data,
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const updateRecord = useCallback(
    function <IType = IServerResponse<Entity.IEntity>>(
      {
        entity,
        id,
        data,
        errorHandler = (error: any) => console.log(error),
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat(
                  "/",
                  entity,
                  "/",
                  id?.toString() || "0"
                ),
                method: "PUT",
                body: data,
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const updateDomainRecord = useCallback(
    function <IType = IServerResponse<Entity.IEntity>>(
      {
        entity,
        id,
        data,
        errorHandler = (error: any) => console.log(error),
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat(
                  "/",
                  entity,
                  "/",
                  id?.toString() || "0",
                  "/domain/",
                  data?.domain.toString() || "0"
                ),
                method: "PUT",
                body: data,
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const createRecord = useCallback(
    function <IType = IServerResponse<{ id: number }>>(
      {
        entity,
        data,
        errorHandler = (error: any) => console.log(error),
        fn = (...args: Array<IType | PromiseLike<IType>>) => void 0,
      }: IParams,
      callback = (...args: Array<IType | PromiseLike<IType>>) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const finish = function (response: IType | PromiseLike<IType>) {
              callback(response);
              resolve(response);
              fn(response);
            };

            sendRequest(
              {
                url: API_Entities_Endpoint.concat("/", entity),
                method: "POST",
                body: data,
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  domain: currentDomain.id,
                },
              },
              finish
            );
          }
        } catch (error) {
          errorHandler(error);
          reject(error);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const bulkCreateRecords = useCallback(
    async ({
      fn,
      entity,
      data,
    }: {
      fn: Function;
      entity: string;
      data: any;
    }) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${environment.apiUrl}/bulks/${entity}`,
            method: "POST",
            body: data,
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const deleteRecord = useCallback(
    function <IType = IServerResponse<Entity.IEntity>>(
      { id = 0, entity = "", fn = (...args: any) => void 0 }: IParams,
      callback = (...args: any) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        const finish = function (response: IType | PromiseLike<IType>) {
          callback(response);
          resolve(response);
          fn(response);
        };

        if (credentials && credentials.logged) {
          sendRequest(
            {
              url: `${API_Entities_Endpoint}/${entity}/${id}`,
              method: "DELETE",
              headers: {
                Authorization: `${credentials.token_type} ${credentials.access_token}`,
                "Content-Type": "application/json",
                domain: currentDomain.id,
              },
              body: [],
            },
            finish
          );
        }
      });
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getRelationshipsWithKey = useCallback(
    function <IType = IServerResponse<RelationshipKey.ILinkedRelationship>>(
      {
        id = 0,
        entity = "",
        key = "",
        page = 1,
        limit = 10,
      }: {
        id: number;
        entity: string;
        key: string;
        page?: number;
        limit?: number;
      },
      callback = (...args: any) => void 0
    ): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        const finish = function (response: IType | PromiseLike<IType>) {
          callback(response);
          resolve(response);
        };

        if (credentials && credentials.logged) {
          sendRequest(
            {
              url: `${API_Entities_Endpoint}/${entity}/${id}/relationships/${key}?page=${page}&limit=${limit}`,
              method: "GET",
              headers: {
                Authorization: `${credentials.token_type} ${credentials.access_token}`,
                "Content-Type": "application/json",
                domain: currentDomain.id,
              },
            },
            finish
          );
        }
      });
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getManyFormated = useCallback(
    async ({ fn }: IGetAllParams) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${API_Entities_Endpoint}/formated`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  return {
    getAllWithRelationships,
    getSingleRecordWithRelationships,
    getFields,
    getEntityProperties,
    getRelationshipsKeys,
    deleteRecord,
    createRelationship,
    getAll,
    getAllWithSingleRelationship,
    validateRecord,
    updateRecord,
    updateDomainRecord,
    createRecord,
    bulkCreateRecords,
    getLayoutProperties,
    getRelationshipsWithKey,
    getManyFormated,
  };
}
