import { Key, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Check2Circle, XCircle, Pencil, Trash } from "react-bootstrap-icons";

import { IPagination, IServerResponse, State } from "src/interfaces";
import { Cell, Column, HeaderCell, Table as RsuiteTable } from "rsuite-table";
import { Pagination } from "rsuite";
import { t } from "i18next";
import DeleteStateModal from "./DeleteStateModal";
import useStateService from "src/services/states.service";
import EditStateModal from "./EditStateModal";

interface TableProps {
  getAll: () => Promise<IServerResponse<State.IState>>;
  refreshData: () => void;
}

const Table: React.FC<TableProps> = ({ getAll, refreshData }) => {
  const { deleteRecord, updateRecord } = useStateService();
  const [response, setResponse] =
    useState<IServerResponse<State.IState> | null>(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<State.IState | null>(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<State.IState | null>(null);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: page,
    limit: limit,
    hasPreviousPage: false,
    hasNextPage: false,
    previousPage: null,
    nextPage: null,
    startIndex: 0,
    numPages: 0,
    recordsTotal: 0,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getAll();
        setPagination({
          ...response.pagination,
          currentPage: 1,
          recordsTotal: response.data.length,
        });
        return setResponse(response);
      } catch (error) {
        return error;
      }
    };
    getData();
  }, [getAll]);

  const handleShowDeleteModal = (item: State.IState) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const handleShowEditModal = (item: State.IState) => {
    setItemToEdit(item);
    setShowEditModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setItemToDelete(null);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setItemToEdit(null);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      deleteRecord({ id: itemToDelete.id })
        .then(async (response) => {
          if (response.error) {
            console.error(response.errors);
            return;
          }
          const res = await getAll();
          setPagination({
            ...res.pagination,
            currentPage: 1,
            recordsTotal: res.data.length,
            limit,
          });
          setResponse(res);
          refreshData();
          handleCloseDeleteModal();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleConfirmEdit = (editedItem: State.IState) => {
    if (editedItem) {
      updateRecord({ id: editedItem?.id, data: editedItem })
        .then(async (response) => {
          if (response.error) {
            console.error(response.errors);
            return;
          }
          const res = await getAll();
          setPagination({
            ...res.pagination,
            currentPage: 1,
            recordsTotal: res.data.length,
          });
          setResponse(res);
          refreshData();
          handleCloseEditModal();
        })
        .catch((error) => {
          console.error(error);
        });
      setShowEditModal(false);
    }
  };

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  const defaultExists = response?.data.some((state) => state.is_default);
  const defaultState = response?.data.find((state) => state.is_default);

  return (
    <div>
      <RsuiteTable autoHeight data={response?.data ?? []}>
        <Column align="center" width={200} sortable>
          <HeaderCell>
            {t("states.table_id", { ns: "application.misc" })}
          </HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column align="center" width={250} sortable>
          <HeaderCell>
            {t("states.table_state", { ns: "application.misc" })}
          </HeaderCell>
          <Cell dataKey="state" />
        </Column>
        <Column align="center" width={250}>
          <HeaderCell>
            {t("states.table_actions", { ns: "application.misc" })}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              rowData.actions.map(
                (action: any, index: Key | null | undefined) => (
                  <span key={index} style={{ marginRight: "4px" }}>
                    {`${t(`states.actions_labels.${action}`, {
                      ns: "application.misc",
                    })}${rowData.actions.length - 1 === index ? "" : ","}`}
                  </span>
                )
              )
            }
          </Cell>
        </Column>
        <Column align="center" width={250}>
          <HeaderCell>
            {t("states.table_default", { ns: "application.misc" })}
          </HeaderCell>
          <Cell>
            {(rowData) =>
              rowData.is_default ? (
                <Check2Circle color="green" size={24} />
              ) : (
                <XCircle color="red" size={24} />
              )
            }
          </Cell>
        </Column>
        <Column align="center" width={250}>
          <HeaderCell>
            {t("states.table_options", { ns: "application.misc" })}
          </HeaderCell>
          <Cell style={{ padding: "4px" }}>
            {(rowData: State.IState) => (
              <div>
                <Button
                  variant="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => handleShowEditModal(rowData)}
                >
                  <Pencil />
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleShowDeleteModal(rowData)}
                >
                  <Trash />
                </Button>
              </div>
            )}
          </Cell>
        </Column>
      </RsuiteTable>
      <Pagination
        last
        first
        ellipsis
        limit={limit}
        className="p-3"
        maxButtons={10}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
        limitOptions={[10, 30, 50]}
        next={pagination.hasNextPage}
        prev={pagination.hasPreviousPage}
        total={pagination.recordsTotal}
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        locale={{
          total: t("label.table.total_records", {
            ns: "application.misc",
            total: "{0}",
          }) as string,
          limit: t("label.table.limit_to", {
            ns: "application.misc",
            limit: "{0}",
          }) as string,
          skip: t("label.table.skip", {
            ns: "application.misc",
            page: "{0}",
          }) as string,
          first: t("label.table.first", { ns: "application.misc" }) as string,
          prev: t("label.table.prev", { ns: "application.misc" }) as string,
          next: t("label.table.next", { ns: "application.misc" }) as string,
          last: t("label.table.last", { ns: "application.misc" }) as string,
          more: t("label.table.more", { ns: "application.misc" }) as string,
        }}
      />
      {showDeleteModal && (
        <DeleteStateModal
          show={showDeleteModal}
          onHide={handleCloseDeleteModal}
          onConfirm={handleConfirmDelete}
          item={itemToDelete}
        />
      )}
      {showEditModal && (
        <EditStateModal
          show={showEditModal}
          onHide={handleCloseEditModal}
          onConfirm={handleConfirmEdit}
          item={itemToEdit}
          defaultExists={defaultExists}
          defaultState={defaultState}
        />
      )}
    </div>
  );
};

export default Table;
