import { useState, useEffect, useCallback } from "react";
import { IUsersResponse, ITokenStorage } from "../interfaces";
import useHttpHandler from "./http-handler";
import environment from "../environment";

import { useDispatch } from "react-redux";
import { setToken } from "../reducers/auth";

export function useLogin() {
  const { sendRequest, isLoading } = useHttpHandler();
  const [login, setLogin] = useState<ITokenStorage>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setToken(login)
    );
    // ! - storedValue don't have to change on first render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);

  const LoginProcess = useCallback(
    (data: URLSearchParams) => {
      sendRequest(
        {
          method: "POST",
          url: `${environment.apiUrl}/users/login`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: data.toString(),
        },
        setLogin
      );
    },
    [sendRequest]
  );

  return {
    login,
    isLoading,
    LoginProcess,
  };
}

export function useSignup() {
  const { sendRequest, isLoading } = useHttpHandler();
  const [signup, setSignup] = useState<IUsersResponse>();

  const SignupProcess = useCallback(
    (data: URLSearchParams) => {
      sendRequest(
        {
          method: "POST",
          url: `${environment.apiUrl}/users/register`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: data.toString(),
        },
        setSignup
      );
    },
    [sendRequest]
  );

  return {
    signup,
    isLoading,
    SignupProcess,
  };
}

const useLoginAuth = {
  useLogin,
  useSignup,
};

export default useLoginAuth;
