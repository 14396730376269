import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, Row, Form, Col } from "react-bootstrap";
import Select from "react-select";
import { IServerResponse, State } from "src/interfaces";
import useStateService from "src/services/states.service";

interface AddStateModalProps {
  show: boolean;
  onHide: () => void;
  stateNames: Array<string> | undefined;
}

const AddStateModal: React.FC<AddStateModalProps> = ({
  show,
  onHide,
  stateNames,
}) => {
  const { createRecord, getAll } = useStateService();
  const [response, setResponse] =
    useState<IServerResponse<State.IState> | null>(null);
  const [state, setState] = useState({
    state: "",
    actions: [],
    is_default: false,
  });
  const [selectedActions, setSelectedActions] = useState<any | null>(null);
  const [isChecked, setIsChecked] = useState<any | boolean>(false);
  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, state: stateNames ? stateNames[0] : "" }));
    const getData = async () => {
      try {
        const response = await getAll();
        return setResponse(response);
      } catch (error) {
        return error;
      }
    };
    getData();
  }, [getAll, stateNames]);

  const options = useMemo(
    () => [
      {
        value: "create",
        label: t("states.actions_labels.create", {
          ns: "application.misc",
        }),
      },
      {
        value: "read",
        label: t("states.actions_labels.read", {
          ns: "application.misc",
        }),
      },
      {
        value: "update",
        label: t("states.actions_labels.update", {
          ns: "application.misc",
        }),
      },
      {
        value: "delete",
        label: t("states.actions_labels.delete", {
          ns: "application.misc",
        }),
      },
    ],
    []
  );

  const defaultExists = response?.data.some((state) => state.is_default);

  const onSubmit = () => {
    createRecord({
      data: {
        ...state,
        actions: selectedActions.map((action: any) => action.value),
        is_default: isChecked,
      },
    })
      .then(async (response) => {
        if (response.error) {
          console.error(response.errors);
          return;
        }
        const res = await getAll();
        setResponse(res);
        onHide();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("states.add_modal.title", { ns: "application.misc" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Form.Group as={Col} controlId="formId">
              <Form.Label>
                {t("states.add_modal.state", { ns: "application.misc" })}
              </Form.Label>
              <Form.Select
                name="state"
                onChange={(e) =>
                  setState((prev: any) =>
                    prev ? { ...prev, state: e.target.value } : null
                  )
                }
                value={state.state}
              >
                {stateNames?.map((state, i) => (
                  <option key={i} value={state}>
                    {state}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formEntity">
              <Form.Label>
                {t("states.add_modal.actions", { ns: "application.misc" })}
              </Form.Label>
              <Select
                options={options}
                value={selectedActions}
                isMulti
                onChange={(selectedActions: any) =>
                  setSelectedActions(selectedActions)
                }
              />
            </Form.Group>
          </Row>
          <Row className="mt-3">
            <Form.Group as={Col}>
              <Form.Check
                inline
                label={t("states.add_modal.default", {
                  ns: "application.misc",
                })}
                name="is_default"
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={defaultExists}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("states.add_modal.btn_cancel", { ns: "application.misc" })}
        </Button>
        <Button
          variant="primary"
          onClick={onSubmit}
          disabled={!selectedActions || !selectedActions.length}
        >
          {t("states.add_modal.btn_add", { ns: "application.misc" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddStateModal;
