import { FC, useEffect, useReducer, useContext, createContext, useMemo, useCallback, useState } from "react";
import { TFunction, withTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";

import { ExclamationCircle } from "react-bootstrap-icons";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import isEqual from "lodash/isEqual";

import PersonUser from "src/models/Config/User/PersonUser";
import useUserDataService from "src/services/user.service";
import DomainList from "../Domain/DomainList";
import { useFormPerson } from "../Form";

export interface UserItemProps {
  key: number;
  user: PersonUser;
  t: TFunction;

  [key: string]: any;
};

const UserItemContext = createContext<any>(null);

const UserItem: FC<UserItemProps> = ({ key, user: item, t }) => {
  const { getDomains, edit, create } = useUserDataService();
  const { reloadUsers } = useFormPerson();

  const [errors, setErrors] = useState<Array<string>>([]);
  const [user, setUserProperty] = useReducer(
    (state: PersonUser, newState: any): PersonUser => new PersonUser({
      ...state,
      ...newState,
      saved: item.isSaved(),
    }),
    new PersonUser({
      ...item,
      password: "",
      saved: item.isSaved(),
    }),
  );

  const modified = useMemo(() => {
    const original = item.asObject();
    const modified = user.asObject();

    original.password = "";
    original.domains = [];
    modified.domains = [];

    return !isEqual(original, modified);
  }, [item, user]);

  const handleSaveCallback = useCallback(() => {
    setErrors([]);
    const save = user.isSaved() ? edit : create;

    if (user.validate()) {
      save({
        id: user.id,
        data: user.asObject(),
        fn: (response: any) => reloadUsers(),
      });
    } else {
      setErrors(user.getErrors());
      setUserProperty(user.asObject());
      console.error(user.getErrors());
    }
  }, [create, edit, user]);

  useEffect(() => {
    if (user.id) {
      getDomains({
        id: user.id,
      })
        .then((response: any) => {
          setUserProperty({
            domains: response.data,
          });
        })
        .catch((err: any) => console.error(err));
    }
  }, [getDomains, user.id]);

  return (
    <UserItemContext.Provider value={{ setUserProperty }}>
      <Form className="bg-white shadow rounded p-3 my-3">
        <Row>
          <Col
            as={Form.Group}
            controlId="user.username"
            xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}
            className="d-flex flex-column justify-content-center align-items-start my-1"
          >
            <Form.Label>
              {t("single_user.username")}
              {
                errors.includes("username")
                && (
                  <ExclamationCircle className="text-danger" />
                )
              }
            </Form.Label>
            <Form.Control
              onChange={e => setUserProperty({ username: e.target.value })}
              placeholder={t("single_user.username")}
              value={user.username}
              type="text"
            />
          </Col>
          <Col
            className="d-flex flex-column justify-content-center align-items-start my-1"
            xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}
            controlId="user.email"
            as={Form.Group}
          >
            <Form.Label>
              {t("single_user.email")}
              {
                errors.includes("email")
                && (
                  <ExclamationCircle className="text-danger" />
                )
              }
            </Form.Label>
            <Form.Control
              onChange={e => setUserProperty({ email: e.target.value })}
              placeholder={t("single_user.email")}
              value={user.email}
              type="email"
            />
          </Col>
          <Col
            className="d-flex flex-column justify-content-center align-items-start my-1"
            xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}
            controlId="user.password"
            as={Form.Group}
          >
            <Form.Label>
              {t("single_user.password")}
              {
                errors.includes("password")
                && (
                  <ExclamationCircle className="text-danger" />
                )
              }
            </Form.Label>
            <Form.Control
              onChange={e => setUserProperty({ password: e.target.value })}
              placeholder={t("single_user.password")}
              value={user.password}
              type="password"
            />
          </Col>
          <Col
            className="d-flex flex-column justify-content-end align-items-end my-1"
            xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}
            controlId="user.form.delete"
            as={Form.Group}
          >
            <ButtonGroup>
              <Button
                onClick={handleSaveCallback}
                variant="outline-primary"
                disabled={!modified}
              >
                {t("label.action.save", { ns: "application.misc" })}
              </Button>
              <Button
                variant="outline-danger"
              >
                {t("label.action.delete", { ns: "application.misc" })}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          {
            user.id
              ? (
                <DomainList
                  ownership={user.domains}
                  subdomain={false}
                  userId={user.id}
                />
              )
              : (
                <Alert
                  variant="secondary"
                  style={{ textAlign: "center" }}
                  children={t("label.help.cannot_select_domain", { ns: "application.misc" })}
                />
              )
          }

        </Row>
      </Form>
    </UserItemContext.Provider>
  );
};

export default withTranslation()(UserItem);

export function useUserItem() {
  const context = useContext(UserItemContext);
  if (context === undefined) {
    throw new Error("useUserItem must be used within a UserItem");
  }
  return context;
};