import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { TFunction } from "i18next";

import { AsyncPaginate } from "react-select-async-paginate";
import { MultiValue, ActionMeta } from "react-select";
import { useTranslation, withTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

import { selectDomain } from "../../../reducers/domain/domainSlice";
import useEntityService from "./../../../services/entity.service";
import { Relationship, Entity, Field } from "../../../interfaces";
import { useAppSelector } from "../../../app/hooks";

import _ from "lodash";

interface DymSelectProps {
  onChange:
    | React.Dispatch<
        React.SetStateAction<{
          [key: string]: Array<Entity.IEntity>;
        }>
      >
    | Function;
  relationshipsKeys: Relationship;
  skipRecords?: Array<any>;
  singleSelect?: boolean;
  targetEntity: string;
  t: TFunction;
}

const DymSelect: React.FC<DymSelectProps> = ({
  relationshipsKeys,
  targetEntity,
  skipRecords,
  onChange,
  t,
}: DymSelectProps) => {
  const currentDomain = useAppSelector(selectDomain);
  const { getAll, getFields } = useEntityService();

  const [namespace, setNamespace] = useState<string | undefined>();

  useLayoutEffect(() => {
    const entity = targetEntity;
    if (typeof entity === "string") {
      setNamespace("entities.".concat(entity));
    }
  }, [targetEntity]);

  const [selected, setSelected] = React.useState<Array<Entity.IEntity>>([]);
  const [entityFields, setFields] = React.useState<Array<Field>>([]);
  const [indexed, setIndexed] = React.useState<Array<string>>([]);

  // ? Dropdown should check if the entity needs multiselection or not.
  // TODO: Review if this logic should be used in the future.
  const multiSelect = useMemo(() => {
    switch (relationshipsKeys.relationship_type) {
      case "many-to-many":
        return true;
      case "one-to-many":
        return true;
      case "many-to-one":
        return false;
      default:
        return true;
    }
  }, [relationshipsKeys.relationship_type]);

  // ? Load the options that will be displayed in the dropdown.
  const loadOptions = useCallback(
    async function (
      search: string,
      loadedOptions: any,
      additional: { page: number } | undefined
    ) {
      let { page } = additional || { page: 0 };
      page = isNaN(Number(page)) ? 1 : Number(page) + 1;
      const { id } = currentDomain;
      const skipRecordsId: Array<number> = [];

      if (skipRecords instanceof Array) {
        for (const value of skipRecords) {
          skipRecordsId.push(value.id);
        }
      }

      if (selected instanceof Array) {
        for (const value of selected) {
          skipRecordsId.push(value.id);
        }
      }

      try {
        if (!id) throw new Error("No domain selected.");
        if (!targetEntity) throw new Error("No relationships found.");

        const response = await getAll({
          entity: targetEntity,
          fn: () => void 0,

          limit: 10, // ! Static limit.
          filter: "&".concat("search", "=", search),
          page,
        });

        const options = [];

        for (const entry of response.data) {
          const label: Array<string> = [];

          if (!skipRecordsId.includes(entry.id)) {
            for (const [field, value] of Object.entries(entry)) {
              if (field.startsWith("data_")) {
                const fname = field.replace(/data_/i, "");
                if (indexed.includes(fname)) {
                  label.push(value);
                }
              }
            }

            if (label.length === 0) {
              label.push(t("entity").concat(" ", entry.id?.toString()));
            }

            options.push({
              value: entry,
              label: label.map((value) => value).join(", "),
            });
          }
        }

        return {
          options,
          additional: { page },
          hasMore: response.data.length === 10,
        };
      } catch (e: unknown) {
        console.error(e);
        return {
          options: [],
          hasMore: true,
        };
      }
    },
    [currentDomain, skipRecords, selected, targetEntity, getAll, indexed, t]
  );

  // ? Handle the change of the dropdown and store current selected values.
  const handleChange = useCallback(
    (
      selected: MultiValue<{
        value: Entity.IEntity;
        label: string;
      }>,
      meta: ActionMeta<{
        value: Entity.IEntity;
        label: string;
      }>
    ): void => {
      const entities: Array<Entity.IEntity> = [];
      for (const { value } of selected) {
        entities.push(value);
      }
      setSelected(entities);

      if (relationshipsKeys.relation_name) {
        const { relation_name } = relationshipsKeys;
        onChange((old) => {
          return {
            ...old,
            [relation_name]: _.uniqBy(entities, "id"),
          };
        });
      }

      return void 0;
    },
    [relationshipsKeys, onChange]
  );

  // ? Get the fields of the target entity.
  useEffect(() => {
    if (targetEntity) {
      getFields({
        fn: () => void 0,
        entity: targetEntity,
      })
        .then((fields) => {
          setFields(fields.data);
          setIndexed(
            fields.data
              .filter((field) => field.indexable)
              .map((field) => field.name)
          );
        })
        .catch(console.error);
    }
  }, [getFields, targetEntity]);

  return (
    <Form.Group className="m-2">
      <Form.Label>
        {t("relationships." + relationshipsKeys.name, {
          ns: "entities." + targetEntity,
        })}
      </Form.Label>
      <AsyncPaginate
        noOptionsMessage={() =>
          t("label.help.no_options_available", { ns: "application.misc" })
        }
        loadOptionsOnMenuOpen={true}
        loadOptions={loadOptions}
        onChange={handleChange}
        additional={{
          page: 0,
        }}
        isMulti
      />
      <Form.Text className="text-muted">
        {!Boolean(indexed.length)
          ? t("label.help.no_indexed_fields", { ns: "application.misc" })
          : entityFields
              .filter((field) => field.indexable)
              .map((field) =>
                t("fields.".concat(field.name), {
                  ns: "entities." + targetEntity,
                })
              )
              .join(", ")}
      </Form.Text>
    </Form.Group>
  );
};

export default withTranslation()(DymSelect);
