import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { IResponse, IServerResponse, State } from "src/interfaces";
import useStateService from "src/services/states.service";
import Table from "./Table";
import useEntityService from "src/services/entity.service";
import AddStateModal from "./AddStateModal";

const States = () => {
  const { getAll: getAllStates } = useEntityService();
  const { checkIfTableExists, getAll } = useStateService();

  const [entityData, setEntityData] = useState<IResponse>();
  const [tableExists, setTableExists] =
    useState<IServerResponse<State.IState> | null>(null);
  const [response, setResponse] =
    useState<IServerResponse<State.IState> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    refreshData();
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await checkIfTableExists();
        setTableExists(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [checkIfTableExists]);

  useEffect(() => {
    getAllStates({ entity: "state", fn: setEntityData });
  }, [getAllStates]);

  const refreshData = useCallback(async () => {
    setLoading(true);
    try {
      const getAllResponse = await getAll();
      setResponse(getAllResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [getAll]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getAll();
        return setResponse(response);
      } catch (error) {
        return error;
      }
    };
    getData();
  }, [getAll]);

  const stateNames = entityData?.data.map((entity) => entity.data_name);

  const statesRegistered = response?.data.map((state) => state.state);

  const statesEnabled = stateNames?.filter(
    (state) => !statesRegistered?.includes(state)
  );

  return (
    <Container fluid>
      <Row>
        <Col className="text-truncate" xxl={10} xs={10} md={6}>
          <h1>{t("config.states")}</h1>
        </Col>
        <Col className="text-truncate" xxl={2} xs={2} md={6}>
          <Button
            variant="primary"
            onClick={() => handleShowAddModal()}
            className="text-nowrap g-0 m-0 p-2"
            children={t("label.action.create", { ns: "application.misc" })}
            disabled={!statesEnabled?.length}
          />
        </Col>
      </Row>
      {loading ? (
        <Row>
          <Col className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </Col>
        </Row>
      ) : tableExists && tableExists?.data ? (
        <Table getAll={getAll} refreshData={refreshData} />
      ) : (
        <h4 className="text-center">
          {t("states.state_doesnt_exists", { ns: "application.misc" })}
        </h4>
      )}
      {showAddModal && (
        <AddStateModal
          show={showAddModal}
          onHide={handleCloseAddModal}
          stateNames={statesEnabled}
        />
      )}
    </Container>
  );
};

export default States;
