import { t } from "i18next";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { State } from "src/interfaces";

interface DeleteStateModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  item: State.IState | null;
}

const DeleteStateModal: React.FC<DeleteStateModalProps> = ({
  show,
  onHide,
  onConfirm,
  item,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("states.delete_modal.title", { ns: "application.misc" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{`${t("states.delete_modal.body", {
        ns: "application.misc",
      })} ${item?.id}?`}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("states.delete_modal.btn_cancel", { ns: "application.misc" })}
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          {t("states.delete_modal.btn_delete", { ns: "application.misc" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteStateModal;
