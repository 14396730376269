interface IPersonUser {
  id?: number;
  type?: string;
  email: string;
  active: boolean;
  username: string;
  password: string;
  domains: number[];
  contact_id: number;
};

export default class PersonUser implements IPersonUser {
  private regexEmail: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // ? Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.
  private regexPassword: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  private saved: boolean = false;

  private errors: string[] = [];

  public id?: number;
  public type?: string;
  public email: string = "";
  public username: string = "";
  public password: string = "";
  public active: boolean = true;
  public domains: number[] = [];
  public contact_id: number = 0;

  constructor(private user: PersonUser | IPersonUser | any) {
    this.username = user.username || "";
    this.password = user.password || "";
    this.active = user.active || false;
    this.domains = user.domains || [];
    this.email = user.email || "";

    this.contact_id = user.contact_id || null;
    this.id = user.id || undefined;
    this.type = user.type || "";
    if (user?.saved) {
      this.setSaved(true);
    }
  }

  public validate(): boolean {
    this.errors = [];

    const fields: { [key: string]: boolean } = {
      contact_id: typeof this.contact_id === "number",
      active: typeof this.active === "boolean",
      domains: this.domains instanceof Array,
      username: !!this.username.length,
      password: this.passwordValid(),
      email: this.emailValid(),
    };

    return Object.keys(fields)
      .every((key: string) => {
        if (!fields[key]) {
          this.errors.push(key);
        }

        return fields[key];
      });
  }

  public getErrors(): string[] {
    return this.errors;
  }

  public emailValid(): boolean {
    return !!this.email.trim().length &&
      this.regexEmail.test(this.email)
  }

  public passwordValid(): boolean {
    return this.password.trim() !== ""
      && this.regexPassword.test(this.password);
  }

  public asObject(): IPersonUser {
    return ({
      username: this.username.trim(),
      password: this.password.trim(),
      contact_id: this.contact_id,
      email: this.email.trim(),
      type: this.type?.trim(),
      domains: this.domains,
      active: this.active,
      id: this.id,
    });
  }

  public isSaved(): boolean {
    return this.saved;
  }

  public setSaved(saved: true = true): void {
    this.saved = saved;
  }
}